
import React, { useEffect, useState, useRef } from 'react';
//import { Cartesian3, Ion,IonTerrainProvider  } from "cesium";
//import * as Cesium from 'cesium';
import { Cartesian3, Ion, IonTerrainProvider, BuildModuleUrl, WebMapServiceImageryProvider, Rectangle, DefaultProxy, Resource,CesiumTerrainProvider,ArcGisMapServerImageryProvider, Terrain,createWorldTerrain } from "cesium";
import { Viewer, Entity, PointGraphics, CameraFlyTo, EntityDescription, ImageryLayer   } from "resium";

import { gt_constant } from '../../business/constanct/gtconstant';
import { get_idtoken } from '../../business/auth/AWSAuth';
//import { Viewer, Entity } from "cesium-react";
//import 'cesium/Build/Cesium/Widgets/widgets.css';
//import CesiumTerrainProvider from 'cesium/Source/Core/CesiumTerrainProvider';
//import { CesiumTerrainProvider } from 'cesium';
Ion.defaultAccessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1MTM4MGE0YS00ZDBiLTRjNmMtYWQ2ZC1kZmQ4MGY1OTZkMjMiLCJpZCI6MTcxMDUxLCJpYXQiOjE2OTY5NDIwNjF9.aECa20iQbIXMjeDbSGRTumllZkhYM3OWm7aaqfc1B7I';
//BuildModuleUrl.setBaseUrl('https://cesium.com/downloads/cesiumjs/releases/1.110/Build/Cesium/');
//const position = Cartesian3.fromDegrees(139.767052, 35.681167, 100);
//https://resium.reearth.io/getting_started
//https://resium.reearth.io/examples/?path=/story/cameraflyto--basic

/**
 * @module GT3DMap
 * @description This component render 3D map using resium.
 * 
 * Parameters
 *  - layername
 *  - bbox
 * 
 * @example 
 * 
 *  <GT3DMap layername={layer_name} bbox={layer_bound)} />
 * 
 * @author Samaresh
 */

const GT3DMap = (props) => {
  const viewerRef = useRef(null);
  const [is_globe_rendered, set_is_globe_rendered] = useState(false);
  /* const terrainProvider = new CesiumTerrainProvider({
     url: 'https://assets.agi.com/stk-terrain/world',
   });*/
   const [layer_name, set_layer_name] = useState(props.layername);
   if(layer_name && layer_name!=props.layername){
    set_layer_name(props.layername);
   }
  
  useEffect(() => {
    set_is_globe_rendered(true);
    /*if (viewerRef.current && viewerRef.current.cesiumElement) {
      
    }
    return () => {
      if (viewerRef.current) {
        viewerRef.current.destroy();
      }
    };*/

  }, []);
  
  
  /*
  [0]=westBoundLongitude
[1]=southBoundLatitude
[2]=eastBoundLongitude
[3]=northBoundLatitude*/
  var west_longitude = 129.23145825152926,  south_latitude= 42.229510001270285, east_longitude = 129.2922573418505, north_latitude = 42.2746792533605;
  if(props.bbox && props.bbox.length>0){
    west_longitude=props.bbox[0];
    south_latitude=props.bbox[1];
    east_longitude=props.bbox[2];
    north_latitude=props.bbox[3];
  }
  const bounding_box = Rectangle.fromDegrees(
    west_longitude,
    south_latitude,
    east_longitude,
    north_latitude
  );
  //const imageryProvider = useMemo(() => new ArcGisMapServerImageryProvider({ url }), [url]);
  let wms_image_provider=null;
  if(layer_name){
     wms_image_provider = new WebMapServiceImageryProvider({
    //url: 'http://localhost:8090/geoserver/wms',
    //layers: 'cus_1_site_2:Musan-Korea_dem',
    layers: layer_name,
    parameters: {
      transparent: true,
      format: "image/png", // Replace with your desired image format
    },
    rectangle: bounding_box,
    url: new Resource({
      url: `${gt_constant.GEOSERVER_WMS_URL}/wms`,

      //proxy: new DefaultProxy('http://localhost:8090/geoserver/wms'),
      headers: {
        Authorization: `Bearer ${get_idtoken()}`,
      }
    })
  });
  }
 

  /*
  const terrainProviderPromise = createWorldTerrainAsync({
    requestWaterMask: true,
    requestVertexNormals: true,
  });*/
  /*const terrainProvider = new CesiumTerrainProvider({
    url: 'https://cesium.com/content/#cesium-world-terrain',
  });*/
  //const terrainProvider = createWorldTerrain();
  /*const imageryProvider = new ArcGisMapServerImageryProvider({
    url: "//services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer",
  });
  const terrainProviderPromise = createWorldTerrain({
    requestWaterMask: true,
    requestVertexNormals: true,
  });*/
  //selectedImageryProviderViewModel
  //selectedTerrainProviderViewModel

  //const arcGisImagery = Cesium.ArcGisMapServerImageryProvider.fromBasemapType(Cesium.ArcGisBaseMapType.SATELLITE);
  /*const arcGISTerrainProvider = Cesium.ArcGISTiledElevationTerrainProvider.fromUrl('https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer', {
    token: apiKey
});*/
//const position = Cartesian3.fromDegrees(-74.0707383, 40.7117244, 100);



  return (
    <div id='3dmapcontainer' key='3dgtmap'  style={{ height: '50vh' }}>
      
      {/* <Viewer full terrainProvider={terrainProvider}>
      <Entity position={position} name="Tokyo">
        <PointGraphics pixelSize={10} />
        <EntityDescription>
          <h1>Hello, world.</h1>
          <p>JSX is available here!</p>
        </EntityDescription>
      </Entity>
    </Viewer> */}
     {is_globe_rendered && <Viewer ref={viewerRef} full homeButton={true} scene3DOnly={true}  fullscreen={true} baseLayerPicker={true} > 
        {props.layername?<CameraFlyTo destination={bounding_box} ></CameraFlyTo>:null}        
        {props.layername?<ImageryLayer key={props.layername} imageryProvider={wms_image_provider} />:null}
      </Viewer> } 
    </div>


  );
};
export default GT3DMap;

{/*<Viewer.TerrainProvider
        url={createWorldTerrain()}
      />
      <IonWorldTerrain />
       <CesiumTerrainProvider url={IonTerrainProvider} />
      
      <Viewer full
            onClick={handleMouseClick}
            onMouseMove={handleMouseClick}
            // terrainProvider={new Cesium.CesiumTerrainProvider({ url: 'https://api.maptiler.com/tiles/terrain-quantized-mesh-v2/?key=xxxxxxx' })}
        >
            <Entity
                name="Tokyo"
                position={Cartesian3.fromDegrees(139.767052, 35.681167, 100)}
                point={{ pixelSize: 10, color: Color.RED }}
            />
        </Viewer>
      */} 