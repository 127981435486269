
import React, { useState, useEffect } from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import GtGauge from '../../components/GTGauge';
//import { product_config } from '../../business/entity/DashboardSheetConfig';
import DataService from '../../business/apigateway/service';
import { gt_constant } from '../../business/constanct/gtconstant';
import { get_company_id, get_idtoken, get_access_token } from '../../business/auth/AWSAuth';
import Preloader from "../../components/Preloader";
import GTCompanyAssociationError from '../../components/GTCompanyAssociationError';
import GTDataServiceError from '../../components/GTDataServiceError';
/**
 * @module GlobalGuardOverview
 * @description This component is tasked with rendering an overall health gauge chart for each product and area of interest (AOI). Initially, it displays the chart grouped by product, but there is an option to change the grouping to AOI. The gauge chart exhibits two needles: one for the current view and the other for the previous value. This enables users to visually assess improvements or declines in health.
 *
 * @example
 *
 * <GlobalGuardOverview  />
 *
 * @author Samaresh
 */
export default () => {
  /** State variable for loading flag */
  const [loaded, set_loaded] = useState(false);
  /** State variable for dataservice availability flag */
  const [data_service_available, set_data_service_available] = useState(true);
  /** State variable to store overall health list from api */
  const [over_view_list, set_over_view_list] = useState([]);
  /*
  const [GTproducts, setGTproducts] = useState(products);
  const [productconfig, setproductconfig] = useState(product_config);
  */
  /** State variable to store company id */
  const [company_id, set_company_id] = useState(0);
  /** State variable to store display by information */
  const [display_by, set_display_by] = useState(0);

  /**
   * @method useEffect
   * @description A hook method that executes only during the initial rendering of the component is implemented here. In this method, It request for overall health data to API.
   */
  useEffect(() => {
    get_overall_health();
  }, []);

  /**
   * @method get_overall_health
   * @description This method check user token and company association. When found this data available it make a request for overall health data.
   */
  const get_overall_health = () => {
    try {
      let user_id_token = get_idtoken();
      let company_id = get_company_id();
      //console.log('company id',company_id)
      if (user_id_token && company_id != -1) {
        let service = new DataService(user_id_token, get_access_token());
        service.get(gt_constant.GT_API_URL, gt_constant.PROVERA_ALL_HEALTH_GET).then((res) => {
          let contents = res.content;
          //console.log('contents=',contents)
          set_loaded(true);
          set_over_view_list(contents);

        }, (error) => {
          console.log('error=', error)
          return set_data_service_available(false);
        }
        ).catch(ex => {
          console.log('ex=', ex)
          if (ex.code == "ERR_NETWORK") {
            return set_data_service_available(false);
          } else if (ex.response && ex.response.status == 500) {
            return set_data_service_available(false);
          }
          if (ex.response && ex.response.data) {
            console.log(ex.response.data.message)
          }
          set_over_view_list([]);
          set_loaded(true);
        });
      } else if (company_id == -1) {
        set_company_id(-1);
      } else if (user_id_token == null) {
        setTimeout(() => {
          get_overall_health();
        }, 500);
      }

    }
    catch (ex) {
      setTimeout(() => {
        get_overall_health();
      }, 500);
    }


  }
  /**
   * @method change_display_view
   * @description This method invoked when display by dropdown has been changed
   */
  const change_display_view = (e) => {
    set_display_by(parseInt(e.target.value));
  }
  /**
   * @method get_product_display_name
   * @description This method return product display name. In database we have product name LULC for land cover but in you UI we show Land cover for this reason, it needs to change the group name to display name.
   */
  const get_product_display_name = (group_name) => {
    if (gt_constant.GT_PRODUCT_CONFIG[group_name]) {
      return gt_constant.GT_PRODUCT_CONFIG[group_name].display_name;
    }
    else {
      return group_name;
    }
  }
  /**
   * @method render_overview_charts
   * @description In this method firt organize the health list according to display by info. Than prepare list of dom for gauge chart and return the list.
   */
  const render_overview_charts = () => {
    let rearranged_overview_list = {};
    /*if(over_view_list && over_view_list.length>1){
      let one=over_view_list[0];
      set_over_view_list([one]);
    }*/

    over_view_list.forEach((ov, index) => {
      let group_by_prop = display_by == 0 ? get_product_display_name(ov.product_group_name) : ov.aoi_name;
      if (rearranged_overview_list[group_by_prop] == null) {
        rearranged_overview_list[group_by_prop] = [];
      }
      rearranged_overview_list[group_by_prop].push(ov);
    });
    //console.log(over_view_list);
    let componet_list = [];

    for (const p in rearranged_overview_list) {
      let overview_data = rearranged_overview_list[p];
     

      componet_list.push(
        <div key={p}>
          <Row className="">
            <Col lg={12}>
              <h5 style={{ color: '#0a58ca' }}>{p}</h5>
            </Col>
          </Row>
          <Row style={{ backgroundColor: '#f8f9fa', paddingTop: '20px', marginBottom: '10px' }}>
            {overview_data.map(data => (
              <Col key={p + '_col_' + data.aoi_name} lg={4} md={4} sm={12} className="mb-4 d-none d-sm-block">
                <GtGauge key={p + '_' + data.aoi_name} minH={200} percent={data.overall_value / 100} oldpercent={data.prev_overall_value / 100}
                  info={display_by == 0 ? data.aoi_name : get_product_display_name(data.product_group_name)}
                  subinfo={(new Date(data.last_updated)).toLocaleDateString()} 
                  description={data.short_description} changetype={data.change_type} 
                  aoi_id={data.aoi_id} product_group={data.product_group_name} />
              </Col>
            ))}
          </Row>
        </div>
      );
    }

    /*GTproducts.forEach((p, index) => {
      let overview_data = over_view_list.filter(o => o.product_group_name == p);
      if (overview_data && overview_data.length > 0) {
        componet_list.push(
          <div key={index}>
            <Row className="">
              <Col lg={12}>
                <h5 style={{ color: '#0a58ca' }}>{productconfig[p].display_name}</h5>
              </Col>
            </Row>
            <Row style={{ backgroundColor: '#f8f9fa', paddingTop: '20px', marginBottom: '10px' }}>
              {overview_data.map(data => (
                <Col key={p + '_col_' + data.aoi_name} lg={4} md={4} sm={12} className="mb-4 d-none d-sm-block">
                  <GtGauge key={p + '_' + data.aoi_name} minH={200} percent={data.overall_value / 100} prevpercent={data.prev_overall_value / 100} info={data.aoi_name} subinfo={(new Date(data.last_updated)).toLocaleDateString()} description={data.short_description} />
                </Col>
              ))}
            </Row>
          </div>
        );
      }
    });*/
    return componet_list;
  };

  return (
    <div style={{ marginTop: 6 }}>
      {data_service_available == false ? <GTDataServiceError key='dataerror' /> : company_id == -1 ? <GTCompanyAssociationError key='companyassociatederror' /> : (<div key='overallhealth'>
        {!loaded ? <Preloader show={!loaded} /> : null}
        <select value={display_by} onChange={change_display_view} className='btn btn-success float-end text-start'>
          <option value='0'>By Product</option>
          <option value='1'>By AOI</option>
        </select>
        <h3 style={{ color: '#052c65' }}>Overall Health</h3>

        <hr />
        {over_view_list?.length > 0 ? render_overview_charts() : ""}
      </div>)}


    </div>
  );
};
