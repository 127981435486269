import axios, { AxiosRequestConfig } from 'axios';
/**
 * @module DataService 
 * @description All kind of http request handle through this service. It needs to create a instance of this class with user token information which is use as authenticaiton header during http request.
 *
 * @author Samaresh
 */
export default class DataService {

  /**
   * @method constructor
   * @description Creates the class instance.
   * @param {String} idtoken.
   * @param {String} accesstoken.
   */
  constructor(idtoken, accesstoken) {
    this.user_id_token = idtoken;
    this.user_access_token = accesstoken;
  }

  /**
   * @method post
   * @description All http post requst execute through this method.
   * @param {String} baseURl.
   * @param {String} endpoint.
   * @param {object} data.
   * @param {object} extheaders.
   */
  async post(baseURl, endpoint, data, extheaders) {
    if (this.user_id_token) {
      let url = baseURl + endpoint;
      let extconfig = {
        headers: {
          'Authorization': 'Bearer ' + this.user_id_token
        }
      };
      try {
        const response = await axios.post(url, data, extconfig)
        return response.data;
      }
      catch (error) {
        console.error(error);
        throw error;
      }
    }

  }
  /**
   * @method get
   * @description All http get requst execute through this method.
   * @param {String} baseURl.
   * @param {String} endpoint.
   */
  async get(baseURl, endpoint) {
    if (this.user_id_token) {
      let url = baseURl + endpoint;
      let extconfig = {
        headers: {
          'Authorization': 'Bearer ' + this.user_id_token
        }
      };
      /*if(extheaders){
        extconfig={
          headers:extheaders
        };
      }*/
      try {
        const response = await axios.get(url, extconfig);
        return response.data;
      }
      catch (error) {  
        console.error(error);
        throw error;
      }
    }


  }
  /**
   * @method put
   * @description All http put requst execute through this method.
   * @param {String} baseURl.
   * @param {String} endpoint.
   * @param {object} data.
   * @param {object} extheaders.
   */
  async put(baseURl, endpoint, data, extheaders) {
    let url = baseURl + endpoint;
    let extconfig = {};
    if (extheaders) {
      extconfig = {
        headers: extheaders
      };
    }
    try {
      const response = await axios.put(url, data, extconfig)
      return response.data;
    }
    catch (error) {
      console.error(error);
      throw error;
    }

  }
  /**
   * @method patch
   * @description All http patch requst execute through this method.
   * @param {String} baseURl.
   * @param {String} endpoint.
   * @param {object} data.
   * @param {object} extheaders.
   */
  async patch(baseURl, endpoint, data, extheaders) {
    let url = baseURl + endpoint;
    let extconfig = {};
    if (extheaders) {
      extconfig = {
        headers: extheaders
      };
    }
    try {
      const response = await axios.patch(url, data, extconfig)
      return response.data;
    }
    catch (error) {
      console.error(error);
      throw error;
    }

  }
  /**
   * @method get_WMS_layer_with_header
   * @description Geo server header request for layers.
   * @param {String} url.
   * @param {String} options.
   */
  async get_WMS_layer_with_header(url, options) {
    try {
      const response = await axios.get(url, options);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

}

