import React from "react";
import { Col, Row, Card, Image, Button, Container, ListGroup, Tooltip, OverlayTrigger, Form, Navbar, Nav, Badge } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faSeedling, faMountain, faWater,  faCloudSunRain, faDna, faPeopleCarry} from "@fortawesome/free-solid-svg-icons";
import landing1 from "../assets/img/dashboard/landing1.jpg";
import TopBanner from '../components/TopBanner';
export default () => {

    const iconstyle = { fontSize: '92px', color: '#64ccc5', fontWeight: '100px' };
    const cardstyle = { minHeight: 222 };
    const defrowcard = { height: 300 }
    return (
        <div data-testid='gglandingpage' className="globalgurdbody">
            <TopBanner source='home' />

            <section style={{ marginTop: 97 }} className="section section-md pb-2  pt-lg-3 dashboardbody">
                <Row className="p-4">
                    <Col lg={4} sm={12} className="order-lg-2 mb-8 mb-lg-0">
                        <div style={defrowcard} className="card card-body border-0 bg-soft shadow mb-4 mb-xl-0">
                            <h2>What is GlobalGuard™?</h2>
                            <p className="mb-4">
                                GlobalGuard™ is GlobalTrust’s world-leading sustainability reporting service. It tracks, analyses and communicates the sustainability performance of clients’ operations, suppliers or investment portfolio. We use the latest satellite imagery and geospatial datasets and advanced analytics to deliver up-to-date social and environmental insights.
                            </p>
                        </div>
                    </Col>
                    <Col lg={4} sm={12} className="order-lg-2 mb-8 mb-lg-0">
                        <center><img style={defrowcard} src={landing1}
                            alt="Esg,Environment,Social,Governance,Investment,Business,Concept.,Women,Use,A"
                            decoding="async" loading="lazy"
                        /></center>

                    </Col>
                    <Col lg={4} sm={12} className="order-lg-2 mb-8 mb-lg-0">
                        <div style={defrowcard} className="card card-body border-0 bg-soft shadow mb-6 mb-xl-0">
                            <h2>How does it work?</h2>
                            <p className="mb-5">
                                GlobalGuard™ provides a trusted and independent sustainability “health check” of specific operations or organisations through ​a simple gauge of the natural and human environment.
                            </p>
                        </div>
                    </Col>

                </Row>
            </section>
            <div className="bg-soft">
                <section className="section section-md bg-soft pt-0 dashboardbody">
                    <Row className="p-4">
                        <Col lg={12}>
                            <center><h2>What can we measure?</h2></center>


                            <br />
                            <br />
                        </Col>
                        <Col lg={4} sm={12}>
                            <div style={cardstyle} className="card card-body  border-0 shadow mb-4 mb-xl-0">
                                <center>
                                    <div>
                                        <FontAwesomeIcon style={iconstyle} icon={faSeedling} className="me-1" />
                                    </div>
                                    <div><h4>Vegetation</h4></div>
                                    <div>Deforestation - Vegetation Health - Vegetation Change</div>
                                </center>
                            </div>
                        </Col>
                        <Col lg={4} sm={12}>
                            <div style={cardstyle} className="card card-body border-0 shadow mb-4 mb-xl-0">
                                <center>
                                    <div>
                                        <FontAwesomeIcon style={iconstyle} icon={faMountain} className="me-1" />
                                    </div>
                                    <div><h4>Land Cover</h4></div>
                                    <div>Broad and Specific Land Cover</div>
                                </center>
                            </div>

                        </Col>
                        <Col lg={4} sm={12}>
                            <div style={cardstyle} className="card card-body border-0 shadow mb-4 mb-xl-0">
                                <center>
                                    <div>
                                        <FontAwesomeIcon style={iconstyle} icon={faWater} className="me-1" />
                                    </div>
                                    <div><h4>Water</h4></div>
                                    <div>Extent - Turbidity - Suspended Solids - Temperature - Algae</div>
                                </center>
                            </div>
                        </Col>
                    </Row>
                    <Row className="p-4">
                        <Col lg={4} sm={12}>
                            <div style={cardstyle} className="card card-body border-0 shadow mb-4 mb-xl-0">
                                <center>
                                    <div>
                                        <FontAwesomeIcon style={iconstyle} icon={faCloudSunRain} className="me-1" />
                                    </div>
                                    <div><h4>Atmospherics</h4></div>
                                    <div>Methane - Sulphur Dioxide - Nitrogen Dioxide - Ozone - Carbon Monoxide</div>
                                </center>
                            </div>
                        </Col>
                        <Col lg={4} sm={12}><div style={cardstyle} className="card card-body border-0 shadow mb-4 mb-xl-0">
                            <center>
                                <div>
                                    <FontAwesomeIcon style={iconstyle} icon={faDna} className="me-1" />
                                </div>
                                <div><h4>Biodiversity</h4></div>
                                <div>Habitat Mapping - Spectral Diversity - Floral Structure - Water Quality</div>
                            </center>
                        </div>

                        </Col>
                        <Col lg={4} sm={12}>
                            <div style={cardstyle} className="card card-body border-0 shadow mb-4 mb-xl-0">
                                <center>
                                    <div>
                                        <FontAwesomeIcon style={iconstyle} icon={faPeopleCarry} className="me-1" />
                                    </div>
                                    <div><h4>Social</h4></div>
                                    <div>Target Stability - Temporary Settlements - Building Count - Access to Green Space</div>
                                </center>
                            </div>
                        </Col>
                    </Row>
                </section>
            </div>

        
        </div>
    );
};
