import React, { useState } from "react";
import GTDashboardButton from './GTDashboardButton';
import { Image } from '@themesberg/react-bootstrap';
import GTLogo from '../assets/img/dashboard/GlobalTrust_Colour_CYMK-01_SSC-Company_2.jpg';

/**
 * @module TopBanner
 * @description This component is responsible for rendering the banner of the GlobalGuard web portal. The banner has two views: one for the landing page and another for the rest of the pages. The component takes two parameters - "source" (with possible values of "home" or "dashboard") and "logoUrl" (the URL of the logged-in user's company). Depending on the "source" value, it renders the appropriate view.
 *
 * @param {string} source - The source of the banner (possible values: "home" or "dashboard").
 * @param {string} [logoUrl] - The URL of the logged-in user's company.
 *
 * @example
 * // Example 1: Rendering for the home page
 * <TopBanner source='home' />
 *
 * // Example 2: Rendering for the dashboard with company logo
 * <TopBanner logourl={companylogo} source='dashboard' companyurl={companyurl}></TopBanner>
 *
 * @author Samaresh
 */


export default (props) => {
    return (
        <>
            {props.source == 'dashboard' ? (
                <div className='top-banner'>
                    <div className="row">
                        <div className="col-md-10 col-sm-12 guard">
                            <h1>GlobalGuard<sup className="tm" >TM</sup></h1>
                        </div>
                        <div className="col-md-2 col-sm-12 logo">
                            {props.logourl?<img src={props.logourl} alt="customer" style={{ marginTop: 17, height: 66 }} />:null}
                        </div>
                    </div>
                </div>) :
                (<div className='topbar'>
                    <div className="row">
                        <div className="col-md-2 col-sm-12 logo">
                            <a href='https://globaltrustgrp.com/' target="_blank">                                
                                <Image alt='GlobalTrust' className="img-fluid ps-5  mt-4" src={GTLogo} height={35} />
                            </a>
                        </div>
                        <div className="col-md-10 col-sm-12 guard">
                            <h1>GlobalGuard<sup className="tm" >TM</sup></h1>
                            <GTDashboardButton />
                        </div>
                    </div>
                </div>)}
        </>
    );
}