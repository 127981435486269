//https://www.sammeechward.com/cognito-user-pool-react
//https://github.com/Sam-Meech-Ward/cognito-user-pool-react
//https://www.npmjs.com/package/amazon-cognito-identity-js
//https://www.sammeechward.com/cognito-user-pool-react
//https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserSession
} from "amazon-cognito-identity-js"
//import { cognitoConfig } from "./CognitoConfig";
import SessionService from "../session/sessionmanagement";
import {gt_constant} from '../constanct/gtconstant'
const userPool = new CognitoUserPool({
  UserPoolId: gt_constant.GT_AWS_COGNITO_USER_POOL_ID,
  ClientId: gt_constant.GT_AWS_COGNITO_CLIENT_ID
})
const user_id_token_session_key='gtcognitouseridtoken';
const user_access_token_session_key='gtcognitouseraccesstoken';
/**
 * @module AWSAuth 
 * @description Cognito user related functionality has been implemented here.
 *
 
 *
 * @author Samaresh
 */

/*
export function signUp(username, email, password) {
  return new Promise((resolve, reject) => {
    userPool.signUp(
      username,
      password,
      [{ Name: "email", Value: email }],
      null,
      (err, result) => {
        if (err) {
          reject(err)
          return
        }
        resolve(result.user)
      }
    )
  })
}

export function confirmSignUp(username, code) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.confirmRegistration(code, true, (err, result) => {
      if (err) {
        reject(err)
        return
      }
      resolve(result)
    })
  })
}
*/
/**
 * @function sign_in
 * @description User authentication logic implemented here.
 * @param {String} username.
 * @param {String} password.
 */
export function sign_in(username, password) {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        resolve(result)
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}
/*
export function forgotPassword(username) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.forgotPassword({
      onSuccess: () => {
        resolve()
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}

export function confirmPassword(username, confirmationCode, newPassword) {
  return new Promise((resolve, reject) => {
    const cognitoUser = new CognitoUser({
      Username: username,
      Pool: userPool,
    })

    cognitoUser.confirmPassword(confirmationCode, newPassword, {
      onSuccess: () => {
        resolve()
      },
      onFailure: (err) => {
        reject(err)
      },
    })
  })
}
*/
/**
 * @function signOut
 * @description User signOut logic implemented here.
 *
 */
export function signOut() {
  const cognitoUser = userPool.getCurrentUser()
  if (cognitoUser) {
    cognitoUser.signOut()
  }
  sessionStorage.clear();
}
/**
 * @function get_current_user
 * @description This function return the current logged in user if available in browser session.
 *
 */
export async function get_current_user() {
  return new Promise((resolve, reject) => {
    const cognitoUser = userPool.getCurrentUser()

    if (!cognitoUser) {
      //window.location = '/';
      reject(new Error("No user found"))
      return
    }

    cognitoUser.getSession((err, session) => {
      if (err) {
        reject(err)
        return
      } else {
        const id_token = session.getIdToken();
        const id_token_expiration = new Date(id_token.payload.exp * 1000);
        const current_time = new Date();

        if (current_time >= id_token_expiration) {
          reject(new Error("Session Expired"));
          return
        }
      }
      cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err)
          return
        }
        const userData = attributes.reduce((acc, attribute) => {
          acc[attribute.Name] = attribute.Value
          return acc
        }, {})

        resolve({ ...userData, username: cognitoUser.username })
      })
    })
  })
}
/**
 * @function get_session
 * @description This function return the current logged user session information which contains all information about congnito user and token.
 *
 */
export function get_session() {
  //const  cognitoUser = userPool.getCurrentUser();
  let cognitoUser = null;
  try{
    cognitoUser = userPool.getCurrentUser();
  }
  catch(ex){
    window.location = '/';
  }
  return new Promise((resolve, reject) => {
    if (!cognitoUser) {
      window.location = '/';
      //reject(new Error("No user found"))
      return
    }
    cognitoUser.getSession((err, session) => {
      if (err) {
        //window.location = '/';
        reject(err);
        return
      } else {
        const id_token = session.getIdToken();
        const access_token=session.accessToken.jwtToken;
        const id_token_expiration = new Date(id_token.payload.exp * 1000);
        const current_time = new Date();

        if (current_time >= id_token_expiration) {
          //reject(new Error("Session Expired"));
          window.location = '/';
          return
        }
        SessionService.set_session(user_id_token_session_key,id_token.jwtToken);
        SessionService.set_session(user_access_token_session_key,access_token);
      }
      resolve(session)
    })
  })

}
/**
 * @function get_idtoken
 * @description This function return the current logged user id token.
 *
 */
export function get_idtoken() {
  get_session();
  return  SessionService.get_session(user_id_token_session_key);
}
/**
 * @function get_access_token
 * @description This function return the current logged user access token.
 *
 */
export function get_access_token() {
  get_session();
  return  SessionService.set_session[user_access_token_session_key];
}
/**
 * @function get_access_token
 * @description This function return the current logged user company id.
 *
 */
export function get_company_id(){
  var token=get_idtoken();
  try{
var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

   var user= JSON.parse(jsonPayload);
   if(user && user['custom:customer_id']){
    return parseInt(user['custom:customer_id']);
   }else{
    return -1;
   }
  }catch(ex){
return -1;
  }
  
}
