
import React, { useEffect, useState } from 'react';

import { Image, Modal, Button } from '@themesberg/react-bootstrap';
import { get_idtoken, get_access_token } from '../business/auth/AWSAuth';
import DataService from '../business/apigateway/service';
import { gt_constant } from '../business/constanct/gtconstant';
import GTPreLoading from './GTPreLoading';
/**
 * @module GTImage
 * @description This component is responsible for rendering an image from Amazon S3. It expects the "url1"(url2 may requre if want to implement compare operation) and "caption" parameter value, 
 * which should contain the S3 resource name. When an S3 request is sent to the API, it renders "GTPreLoading" to indicate the progress of the operation. Upon a successful request operation, 
 * it expects the response to be a base64 image; otherwise, it renders the default image from the constant "gt_constant.GT_DEFAULT_IMAGE." Additionally, this component features 
 * an expand image option. When a user clicks on the image, it opens a modal window displaying an enlarged view of the image.
 * Initial loading value set "True" and from hook it make request for image from s3.
 * 
 * Parameters
 *  - url1 s3 resource name
 *  - caption product name
 * 
 * @example 
 * <GTImage url1={mockUrl} caption={mockCaption} />
 * 
 * @author Samaresh
 */

const GTImage = (props) => {

    /** State variable for flag modal show or not  */
    const [show_default, set_show_default] = useState(false);
    /** State variable for flag loading image */
    const [loading, set_loading] = useState(true);    
    /** State variable to store image url */
    const [image_url, set_image_url] = useState(null);
    
  /**
   * @method useEffect
   * @description Hook method which execute only when first time it render the componet.
   */
    useEffect(() => {
        load_image();
    }, []);
  /**
   * @method handle_close
   * @description Invoked when modal needs to close.
   */
    const handle_close = () => set_show_default(false);
  /**
   * @method load_image
   * @description This method send request to API to read the base64 image from aws s3.
   */
    const load_image = async () => {
        if (props.url1) {
            const handle_expection = (ex) => {
                //console.log(ex);
                set_loading(false);
                set_image_url(gt_constant.GT_DEFALUT_IMAGE);
                //console.log(ex.response.data.message);
            }
            try {
                const dataservice = new DataService(get_idtoken(), get_access_token());
                dataservice.post(gt_constant.GT_API_URL, gt_constant.GT_API_DOWNLOAD_IMAGE, { filename: props.url1 })
                    .then(
                        (res) => {
                            //console.log(res);
                            set_image_url(res);
                            set_loading(false);
                        },
                        (error) => {
                            handle_expection(error);
                        }
                    )
                    .catch(ex => {
                        handle_expection(ex);
                    });
            }
            catch (ex) {
                handle_expection(ex);
            }

        }
    }
    return (
        <div data-testid='gtimagecontainer'>
            {loading ? <GTPreLoading /> : null}
            {image_url ? (<div className="roundbox border-1 bg-soft shadow">
                <div className='text-center p3'><span style={{ fontSize: '16px', fontWeight: 'bold', padding: '8px' }}>{props.caption}</span> </div>
                <Image alt={props.caption} onClick={() => { set_show_default(true); }} src={image_url} width='100%' />
            </div>) : null}

            <Modal data-testid='gtplotmodal' as={Modal.Dialog} size='xl' centered show={show_default} onHide={handle_close}>
                <Modal.Header>
                    <Modal.Title className="h6">{props.caption}</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handle_close} />
                </Modal.Header>
                <Modal.Body>
                    <div style={{ overflow: 'auto', maxHeight: '85vh' }}>
                        <Image data-testid='imginsidemodal' alt={props.caption} src={image_url} style={{ minHeight: '60vh', maxWidth: 'none' }} />
                    </div>

                </Modal.Body>
                {/* <Modal.Footer>                    
                    <Button variant="link" className="text-gray ms-auto" onClick={handle_close}>
                        Close
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </div>

    );
};
export default GTImage;