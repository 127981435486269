
import React, { useEffect, useState, useRef } from 'react';
//import { dashboard_config } from '../../business/entity/DashboardSheetConfig';

import { gt_constant } from '../../business/constanct/gtconstant';
import SessionService from '../../business/session/sessionmanagement';
import DataService from '../../business/apigateway/service';
import TableauNotFound from '../error/TableauNotFound';
import { get_idtoken, get_access_token, get_company_id } from '../../business/auth/AWSAuth';
/**
 * @module GTTableau
 * @description This component renders a Tableau dashboard based on the provided parameters. The web portal maintains a Tableau dashboard URL for each product, and depending on the product, it renders the corresponding dashboard. To ensure that it doesn't prompt for authentication during the Tableau dashboard request, it requires a Tableau token obtained from the API. @stoddabr/react-tableau-embed-live npm library used to render tableau react embed component <tableau-viz>
 *
 * Parameters: 
 *  - dashboard String
 *  - aoi_id Number
 *  - sites Array of object
 * 
 * @example
 * 
 * <GTTableau dashboard={dashboard} aoi_id={aoi_id} sites={sites}
 *
 * @author Samaresh
 */
const GTTableau = (props) => {
    let vizRef = useRef(null);
    /** State variable for current dashboard value */
    const [curret_dashboard, set_curret_dashboard] = useState(props.dashboard);
    /** State variable for current aoi */
    const [curret_aoi_id, set_curret_aoi_id] = useState(props.aoi_id);
    //const [should_change_url, set_should_change_url] = useState(true);
    //const [should_register, set_should_register] = useState(true);
    /** State variable for tableau token availability flag, when the component wait for token changing the flag trigger to render the component again */
    const [tableau_token_available, set_tableau_token_available] = useState(false);
    /** State variable for tableau server availability flag, when the component wait for load dashboard changing the flag trigger to render the component again */
    const [tableau_server_available, set_server_token_available] = useState(true);
    //let gt_dashboard = null;
    //let filter_applied = false;
    /*const handleFirstInteractive = async (firsthandleobj) => {
      if (!filter_applied) {
        filter_applied = true;
      }
    }*/

    /**
     * @method get_tableau_token
     * @description The logic for obtaining the Tableau token is implemented within this method. Initially, it verifies the validity of the user_id_token and company association. Upon confirming the validity of this information, it proceeds to request the Tableau token. The acquired token is set to remain valid for two minutes, prompting the establishment of a timer mechanism. This timer triggers a token request and updates the session every two minutes. As soon as a valid token is available, it initiates a request for the Tableau dashboard.
     */
    const get_tableau_token = (isnew) => {
        let company_id = get_company_id();
        let user_id_token = get_idtoken();
        let dataservice = new DataService(user_id_token, get_access_token());
        if (user_id_token && company_id !== -1) {
            try {
                dataservice.post(gt_constant.GT_API_URL, gt_constant.GT_API_TABLEAU_TOKEN).then((res) => {
                    //console.log(res);
                    if (res && res.token) {
                        SessionService.set_session(gt_constant.TABLEAU_TOKEN_SESSION_KEY, res.token);
                        if (isnew) {
                            set_tableau_token_available(true);
                        }
                    }

                }, fail => {
                    if (!isnew) {
                        window.location = '/';
                    }
                }
                ).catch(error => {
                    if (!isnew) {
                        window.location = '/';
                    }
                });
            }
            catch (ex) {
                if (!isnew) {
                    window.location = '/';
                }
            }

        }
        /** Timmer to refresh the tableau token */
        setTimeout(() => {
            get_tableau_token(false);
        }, 1000 * 2 * 60);
    }
    /**
     * @method useEffect
     * @description Implemented as a hook method, this function specifically runs during the initial rendering of the component. It begins by clearing the Tableau token session to ensure any old or expired tokens are removed. Subsequently, it initiates a request for a new token. 
     */
    useEffect((obj) => {
        SessionService.remove_session(gt_constant.TABLEAU_TOKEN_SESSION_KEY);
        get_tableau_token(true);
    }, [])
    /*
        useEffect((obj) => {
    
            //if (vizRef && vizRef.current && should_change_url) 
            if (vizRef && vizRef.current) 
            {
                //set_should_change_url(false);
                //gt_dashboard = document.getElementById("tableauViz");
                // if (should_register) {
                //     gt_dashboard.addEventListener("firstinteractive", handleFirstInteractive);
                //     set_should_register(false);
                // }
                //set_should_change_url(false);
                //apply_filter_to_sheet();
    
            }
        }, [vizRef, curret_dashboard, curret_aoi_id])
    */
    /** Check dashboard request change or or not when new request come changing current dashboard state it enforce to render new dashboard */
    /** If dashbaord remain same but AOI has been changed it also change the dashboard filter value with new aoi to load appropriate data */
    if (curret_dashboard !== props.dashboard) {
        //set_should_change_url(true);
        set_curret_dashboard(props.dashboard);
    } else if (props.aoi_id !== -1 && curret_aoi_id !== -1 && props.aoi_id !== curret_aoi_id) {
        try {
            set_curret_aoi_id(props.aoi_id);
        }
        catch (ex) {
            console.error(ex);
        }

    }
    /**
     * @method apply_filter_to_sheet
     * @description The Tableau dashboard is composed of different sheets, and at times, specific filters need to be applied to a sheet. This method facilitates the application of filters based on the provided configuration from the <b>sheetConfig</b>. It iterates through all available sheets in the dashboard, matches the sheet names from the configuration, and upon finding a match, applies the specified filter to the sheet. It call tableau API to perform the filter.
     */
    const apply_filter_to_sheet = (count) => {
        if(count==100){
            set_server_token_available(false);
        }
        count++;
        try {
            if (vizRef.current.workbook && vizRef.current.workbook.activeSheet && vizRef.current.workbook.activeSheet.worksheets) {
                gt_constant.GT_DASHBOARD_CONFIG[props.dashboard].Sheets.forEach(async (sheetconfig, index) => {
                    let sheet = vizRef.current.workbook.activeSheet.worksheets.find(sheet => sheet.name === sheetconfig.sheet);
                    sheetconfig.Filters.forEach(async (filter, index) => {
                        let filterFieldName = filter.filterFieldName;
                        let filterValue = [0];
                        if (filter.filterValueFromCurrent) {
                            filterValue = [props.aoi_id];
                        }
                        if (filter.filterValueFromAllAOI) {
                            filterValue = props.sites.map(s => s.aoi_id);
                        }
                        if (filter.filterValue) {
                            filterValue = [filter.filterValue];
                        }
                        if (sheet) {
                            await sheet.applyFilterAsync(filterFieldName, filterValue, 'replace', false).then((res) => {

                            }).catch(ex => {
                                throw new Error("not found sheet try again");
                            });
                        } else {
                            throw new Error("not found sheet try again");
                        }
                    });
                });


            }
            else {
                setTimeout(() => {
                    apply_filter_to_sheet(count);
                }, 500);
            }
        } catch (ex) {
            setTimeout(() => {
                apply_filter_to_sheet(count);
            }, 500);
        }
    }
    /**
     * @method get_extra_filers
     * @description This method return extra filter dom for tableau component. So far only for social dashboard we had to use this. 
     */
    const get_extra_filers = () => {
        switch (props.dashboard) {
            case 'Social':
                return <viz-filter field="aoi_name" value={props.sites.map(s => s.aoi_name)} />
            default:
                return null;
        }
    }
    /**
     * @method get_dashboard_component
     * @description This method return tableau dashboard component dom when all the valid information available other wise return null.
     */
    const get_dashboard_component = () => {

        let aoilist = [props.aoi_id];
        let current_dash_config = props.dashboard ? gt_constant.GT_DASHBOARD_CONFIG[props.dashboard] : null;
        let tableeau_token = SessionService.get_session(gt_constant.TABLEAU_TOKEN_SESSION_KEY);
        if (current_dash_config == null || !tableeau_token) {
            return null;
        }

        /*if (vizRef && vizRef.current) {
            //vizRef.current.dispose();
        }*/
if(!tableau_server_available){
    return (<TableauNotFound />)
}
        return (
            <tableau-viz height={current_dash_config.height} width={current_dash_config.width + 5} id="tableauViz" hide-tabs toolbar='hidden'
                src={gt_constant.GT_TABLEAU_SITE + current_dash_config.url + '?t=' + curret_dashboard + '-' + props.aoi_id}
                token={tableeau_token}
                ref={vizRef}>
                <viz-filter field="customer_id" value={get_company_id()} />
                <viz-filter field="aoi_id" value={aoilist} />
                {get_extra_filers()}
                {setTimeout(() => {
                    apply_filter_to_sheet(0);
                }, 500)}
            </tableau-viz>
        );
    }

    let dash_width = props.dashboard ? gt_constant.GT_DASHBOARD_CONFIG[props.dashboard]?.width : 0;
    return (
        <div data-testid='gtdashboard' id="gtdashboard" style={{ marginTop: '6px', width: dash_width + 10 }}>
            {dash_width > 0 ? get_dashboard_component() : null}
        </div>
    );
};
export default GTTableau;