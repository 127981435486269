
import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";

// core styles
import "./scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

import HomePage from "./pages/HomePage";
import ScrollToTop from "./components/ScrollToTop";
/**
 * @module index
 * @description This is the entry point for each request. It locates the DOM element with the id 'root,' identifies the React root, and renders the HomePage component into it.
 * 
 * @author Samaresh
 */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
  <ScrollToTop />
  <React.StrictMode>
    <HomePage />
  </React.StrictMode>
</HashRouter>
);
/*
ReactDOM.render(
  <HashRouter>
    <ScrollToTop />
    <HomePage />
  </HashRouter>,
  document.getElementById("root")
);*/
