/**
 * @module Routes
 * @description Rout configuration
 *
 * @author Samaresh
 */
export const Routes = {
    // pages
    GTIndex: { path: "/" },
    Presentation: { path: "/presentation" },
    DashboardOverview: { path: "/dashboard/overview" },
    //GlobalGurdDashboard: { path: "/dashboard/GlobalGurdDashboard" }, 
    Vegetation: { path: "/dashboard/Vegetation" }, 
    LandCover: { path: "/dashboard/LandCover" }, 
    Water: { path: "/dashboard/Water" }, 
    Atmospheric: { path: "/dashboard/Atmospheric" }, 
    Social: { path: "/dashboard/Social" }, 
    Biodiversity:{ path: "/dashboard/Biodiversity" },     
    NotFound: { path: "/dashboard/404" },
    ServerError: { path: "/dashboard/500" },
    NoProductFound: { path: "/noproduct" },
/*
    // docs
    DocsOverview: { path: "/documentation/overview" },
    DocsDownload: { path: "/documentation/download" },
    DocsQuickStart: { path: "/documentation/quick-start" },
    DocsLicense: { path: "/documentation/license" },
    DocsFolderStructure: { path: "/documentation/folder-structure" },
    DocsBuild: { path: "/documentation/build-tools" },
    DocsChangelog: { path: "/documentation/changelog" },

    // components
    Accordions: { path: "/components/accordions" },
    Alerts: { path: "/components/alerts" },
    Badges: { path: "/components/badges" },
    Widgets: { path: "/widgets" },
    Breadcrumbs: { path: "/components/breadcrumbs" },
    Buttons: { path: "/components/buttons" },
    Forms: { path: "/components/forms" },
    Modals: { path: "/components/modals" },
    Navs: { path: "/components/navs" },
    Navbars: { path: "/components/navbars" },
    Pagination: { path: "/components/pagination" },
    Popovers: { path: "/components/popovers" },
    Progress: { path: "/components/progress" },
    Tables: { path: "/components/tables" },
    Tabs: { path: "/components/tabs" },
    Tooltips: { path: "/components/tooltips" },
    Toasts: { path: "/components/toasts" },
    WidgetsComponent: { path: "/components/widgets" }*/
};