
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';

import GTLogo from '../assets/img/dashboard/logo-bg.png';

/**
 * @module Preloader
 * @description This component is tasked with displaying the progress of page loading. It accepts a boolean parameter, "show." When the value is true, 
 * it becomes visible on top of all content. Conversely, when the value is false, it conceals this progress indicator.
 * 
 * Parameters
 *  - show bool
 * 
 * @example 
 * <Preloader show='false'></Preloader>
 * 
 * @author Samaresh
 */

export default (props) => {

  const { show } = props;

  return (
    <div data-testid='preloadcontainer' className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
      <Image alt='gtloading' className="loader-element animate__animated animate__jackInTheBox" src={GTLogo} height={60} />
    </div>
  );
};
