import { createTileLayerComponent, updateGridLayer, withPane } from '@react-leaflet/core';
import { TileLayer } from 'leaflet';
import { get_idtoken } from '../../business/auth/AWSAuth';

  /**
   * @method fetch_image
   * @description This method fetch the wms tiles from geo server and process the blob.
   */
async function fetch_image(url, callback, headers, abort) {
  headers = {
    Authorization: get_idtoken(), // Replace with your actual access token or authorization method
    //requestsource:'gtlocaloffice'
  };
  const controller = new AbortController();
  const signal = controller.signal;
  if (abort) {
    abort.subscribe(() => {
      controller.abort();
    });
  }
  try{
    const f = await fetch(url, {
      method: "GET",
      headers: headers,
      mode: "cors",
      signal: signal
    });
    const blob = await f.blob();
    callback(blob);
  }
  catch(ex){
    console.error(ex);
  }
  
}
  /**
   * @class LWMSTileLayerWithHeader
   * @description This is extended class from TileLayer.WMS
   */

var LWMSTileLayerWithHeader = TileLayer.WMS.extend({
  initialize: function (url, options) {
    const { headers, abort, results, ...props } = options;
    TileLayer.WMS.prototype.initialize.call(this, url, props);
    this.headers = headers;
    this.abort = abort;
    this.results = results;
  },
  createTile(coords, done) {
    const url = this.getTileUrl(coords);
    const img = document.createElement("img");
    img.setAttribute("role", "presentation");

    fetch_image(
      url,
      resp => {
        const reader = new FileReader();
        reader.onload = () => {
          img.src = reader.result;
          if (this.results) {
            this.results.next(reader.result);
          };
        };
        reader.readAsDataURL(resp);
        done(null, img);
      },
      this.headers,
      this.abort
    );
    return img;
  }
});
/**
 * @module WMSTileLayerWithHeader
 * @description This is WMS Tiles Layer header extension. We are using geo server through aws api gateway which needs authorization header in each request. This extended comnpoment catch each request and add the authorization header.
 * 
 * Parameters
 *  - key
 *  - url
 *  - layers
 *  - format
 *  - transparent
 *  - bounds
 *  - tiled
 *  - headers
 *  - version
 *  - opacity
 * 
 * @example 
 * 
 *  <WMSTileLayerWithHeader key={layer_name} url={wmsurl} layers={layer_name} format="image/png" transparent={true} bounds={bounds} tiled={true} headers={get_headers()} version="1.1.0" opacity={1} styles="NDVI" />
 * 
 * @author Samaresh
 */
export const WMSTileLayerWithHeader = createTileLayerComponent(
  function createWMSTileLayer({ params = {}, url, ...options }, context) {
    return {
      instance: new LWMSTileLayerWithHeader(url, {
        ...params,
        ...withPane(options, context)
      }),
      context
    };
  }, function updateWMSTileLayer(layer, props, prevProps) {
    updateGridLayer(layer, props, prevProps);

    if (props.params != null && props.params !== prevProps.params) {
      layer.setParams(props.params);
    }
  });