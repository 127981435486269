export default class SessionService {
    static set_session(key, value) {
        sessionStorage.setItem(key, value);
    }
    static get_session(key) {
       return sessionStorage.getItem(key);
    }
    static remove_session(key) {
        sessionStorage.removeItem(key);
    }
}

