
import React, { useEffect, useState,useRef } from 'react';
import { gt_constant } from '../../business/constanct/gtconstant';
import SessionService from '../../business/session/sessionmanagement';
import {Rectangle} from "cesium";
import { get_idtoken, get_access_token } from '../../business/auth/AWSAuth';
/**
 * @module GTGlobalMap
 * @description This component is responsible for rendering map from external source in ifram.
 * 
 
 * 
 * @example 
 * <GTGlobalMap  />
 * 
 * @author Samaresh
 */

const GTGlobalMap = (props) => {
    const viewerRef = useRef(null);
   
   
    
  /**
   * @method useEffect
   * @description Hook method which execute only when first time it render the componet.
   */
    useEffect(() => {


        
    }, []);
    useEffect(() => {
    
        if (viewerRef.current) {
            window.addEventListener('message', (event) => {
                
                let sessionkey='';
                if (event.data.message === 'get_gg_wms_url') {
                 sessionkey=gt_constant.MAP_WMS_REQUEST_SESSION_KEY;
                }else if(event.data.message === 'get_gg_user_token'){
                    sessionkey='gtcognitouseridtoken';
                }
                /*
                    <EX_GeographicBoundingBox>
                    <westBoundLongitude>30.374888454390057</westBoundLongitude>
                    <eastBoundLongitude>30.384970006865824</eastBoundLongitude>
                    <southBoundLatitude>31.440262748955778</southBoundLatitude>
                    <northBoundLatitude>31.44645142473298</northBoundLatitude>
                    </EX_GeographicBoundingBox>
                */
                if(sessionkey){
                    var west_longitude = 129.23145825152926,  south_latitude= 42.229510001270285, east_longitude = 129.2922573418505, north_latitude = 42.2746792533605;
                    if(props.bbox && props.bbox.length>0){
                        west_longitude=props.bbox[0];
                        south_latitude=props.bbox[1];
                        east_longitude=props.bbox[2];
                        north_latitude=props.bbox[3];
                      }
                      const bounding_box = Rectangle.fromDegrees(
                        west_longitude,
                        south_latitude,
                        east_longitude,
                        north_latitude
                      );
                    sessionkey=gt_constant.MAP_WMS_REQUEST_SESSION_KEY;
                    viewerRef.current?.contentWindow.postMessage(
                        { 
                            message: 'gg_map_values', 
                            data:{
                                wms_url:SessionService.get_session(sessionkey),
                                wms_legend_url:`${gt_constant.GEOSERVER_WMS_URL}${gt_constant.GEOSERVER_LEGEND_URL_PARAMETER}`,
                                cognito_user_token:SessionService.get_session('gtcognitouseridtoken'),
                                layer_prefix:[props.wmslayerprefix,`${props.wmslayerprefix.split(':')[0]}:rgb_`] ,
                                bbox:props.bbox
                            }  
                        }, '*');


                    //viewerRef.current?.contentWindow.postMessage({ message: 'get_gg_wms_url', data: SessionService.get_session(sessionkey) }, '*');

                    //sessionkey='gtcognitouseridtoken';
                    //viewerRef.current?.contentWindow.postMessage({ message: 'get_gg_user_token', data: SessionService.get_session(sessionkey) }, '*');
                    
                    //let rgbprifix = `${props.wmslayerprefix.split(':')[0]}:rgb_`;
                    //let filteredlayers = props.wmscapability?.Capability?.Layer?.Layer?.filter(f => f.Name.indexOf(props.wmslayerprefix) == 0);

                    // viewerRef.current?.contentWindow.postMessage({ message: 'get_gg_layer_filter', data: [props.wmslayerprefix] }, '*');
                    //frame.contentWindow.postMessage({ message: event.data.message, data: SessionService.get_session(sessionkey) }, '*'); // Replace '*' with specific origin if needed
                    //viewerRef.current.contentWindow.postMessage({ message: event.data.message, data: SessionService.get_session(sessionkey) }, '*');
                    /*setTimeout(() => {  
                        viewerRef.current.contentWindow.postMessage({ message: event.data.message, data: SessionService.get_session(sessionkey) }, '*');
                       }, [100]);*/
                }
              });
        }
    
        return () => {
            if (viewerRef.current) {
                viewerRef.current.removeEventListener('message', ()=>{});
            }
        };
    }, [viewerRef.current]);

    return (
        <div data-testid='GTGlobalMapcontainer'>
            <iframe ref={viewerRef} id="gtextmap" src={gt_constant.GT_MAP_SITE_URL+'?token='+get_idtoken()} style={{width:'98vw',height:'calc(100vh - 125px)'}}/>
        </div>

    );
};
export default GTGlobalMap;