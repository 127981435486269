
import React from 'react';
import { Image } from '@themesberg/react-bootstrap';
import GTLogo from '../assets/img/dashboard/logo-bg.png';
/**
 * @module GTPreLoading
 * @description This component is responsible for rendering progress for specific operations, such as login progress or image loading progress. 
 * It does not require any parameters. The decision to render it or not depends on the parent component, which determines whether 
 * the operation is in progress or completed.
 *  
 * Render:
 *  - <GTPreLoading />
 * @author Samaresh
 */

const GTPreLoading = (props) => {

    return (
        <div className="requestprogress flex-column justify-content-center align-items-center">
            <Image alt='gtloading' className="loader-element animate__animated animate__jackInTheBox" src={GTLogo} height={40} />
        </div>

    );
};
export default GTPreLoading;