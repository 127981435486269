
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import { Routes } from "../../routes";
import NotFoundImage from "../../assets/img/illustrations/404.svg";
/**
 * @module NotFound
 * @description This page renders an error message for a <b>"Page Not Found"</b> scenario. If a user enters an incorrect URL, this error page will be displayed.
 *
 * @example
 *
 * <NotFound  />
 *
 * @author Samaresh
 */

export default () => {
  return (
    <main data-testid='ggtableaunotfounderror'>
      <section className="d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <Card.Link as={Link} to={Routes.DashboardOverview.path}>
                  <Image src={NotFoundImage} className="img-fluid w-75" />
                </Card.Link>
                <h1 className="text-primary mt-5">
                  Dashboard server not available
                </h1>
                <p className="lead my-4">Oops! Looks like Tableau server is not availeble. Please refresh the page to try again or contact to administration.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
