import React, { useState } from "react";
import SessionService from "../business/session/sessionmanagement";

/**
 * @module SiteMenu
 * @description This element is designed to showcase the associated product site (AOI) as an action tab. When a user clicks on any tab that is not currently selected, 
 * it will transmit the new AOI value to the parent component by calling change_aoi and modify the CSS of the selected tab.
 * In the future, if there is a substantial number of sites for a product, we will display a few items as tabs, and the remaining all will be placed in a 
 * dropdown menu. The default selection for the menu will be the first site item. If the product selection changes and the chosen product is also linked to 
 * the currently selected site, the site selection will persist instead of defaulting to the first item.
 * 
 * Parameters
 *  - sites Array of object like [{ aoi_id: 1, aoi_name: 'Site 1' }, { aoi_id: 2, aoi_name: 'Site 2' }]
 *  - change_aoi a function which will be called with selected aoi id when user select new aoi
 *  
 * @example 
 * <SiteMenu sites={mockSites} change_aoi={mockChangeAOI} /> *
 * 
 * @author Samaresh
 */

export default (props) => {
  const [aoi_id, set_aoi_id] = useState(0);

  
  /**
  * @method select_new_site
  * @description This method called when user click on tab menu, it set the new aoi id to state and call the parent mehtod change_aoi.
  * @param {Number} id The new aoi id
  */
  const select_new_site = (id) => {
    if (id != aoi_id) {
      set_aoi_id(id);
      if (props.change_aoi) {
        props.change_aoi(id);
      }
    }
    SessionService.remove_session('selected_aoi');
  }
  /*
  This method set the default selection and prepare the dom list for Sites
  */
 /**
  * @method get_site_menus
  * @description This method set the default selection and prepare the dom list for Sites. This method iterate the sites and prepare a dom action for each site.
  * From the list of site first one will be default selected. If selected_aoi session value exist it will be default selected.
  * @param {Array} sites The list of sites provided as parameter during module call
  *  @returns {Array} site_list list of dom.
  */
  const get_site_menus = (sites) => {
    let site_list = [];
    let site1 = sites.find(s => s.aoi_id == aoi_id);
    if (!site1 && props.sites && props.sites.length > 0) {
      let session_aoi_id=SessionService.get_session('selected_aoi');
      let current_aoi_id=props.sites[0].aoi_id;
      if(session_aoi_id){
        site1 = sites.find(s => s.aoi_id == parseInt(session_aoi_id));
        if(site1){
          current_aoi_id=parseInt(session_aoi_id) ;   
        }else{
          SessionService.remove_session('selected_aoi');
        }
             
      }
      set_aoi_id(current_aoi_id);
      props.change_aoi(current_aoi_id);
    }
    // Loop the site to prepare action link
    sites.forEach(s => {
      let classes = s.aoi_id == aoi_id ? 'nav-item navactive' : 'nav-item';
      // To avoid console error we should add unique key for each dom.
      let key = 'AOIID_' + s.aoi_id;
      site_list.push(
        <li key={key} className={classes}>
          <a className="nav-link" onClick={() => { select_new_site(s.aoi_id) }}>{s.aoi_name}</a>
        </li>
      );
    });
    return site_list;
  }
  return (
    <div className="site-menu">
      <nav className="navbar navbar-expand-lg navbar-light  custom-navbar">
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {get_site_menus(props.sites)}
          </ul>
        </div>
      </nav>
    </div>
  );
}