
import React, { useState, memo } from "react";
import SimpleBar from 'simplebar-react';
import { useLocation } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faBoxOpen, faHome, faChartPie, faLeaf, faMap, faWater, faCloud, faCity, faDna, faFileAlt, faHandHoldingUsd, faSignOutAlt, faTable, faTimes, faCalendarAlt, faMapPin, faInbox, faRocket } from "@fortawesome/free-solid-svg-icons";
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from "../routes";
//import { products, product_config } from '../business/entity/DashboardSheetConfig';
import GTLogo from '../assets/img/dashboard/GlobalTrust_Colour_CYMK-01_SSC-Company_2.jpg';
import GTWHITELogo from '../assets/img/dashboard/gt_white.png';
import { gt_constant } from '../business/constanct/gtconstant';
/**
 * @module Sidebar
 * @description This component renders the left menu displaying products associated with the logged-in user's customer account. 
 * The "Overview" menu item is positioned as the initial item in the left menu. The GlobalTrust logo is displayed at the top, 
 * serving as a link to the GlobalGuard landing page. Clicking on any menu item redirects the user to the corresponding page.
 * This can render fixed 6 types of product 'Vegetation','LULC','Water','Atmospherics','Social' and 'Biodiversity'
 * 
 * Parameters
 *  - products Array of object like ['Vegetation', 'LULC', 'Water', 'Atmospherics', 'Social', 'Biodiversity']
 *  
 * @example 
 * <Sidebar products={productList} />
 * 
 * @author Samaresh
 */

const Sidebar1 = (props = {}) => {
  // Hook to get the current location
  const location = useLocation();
  const { pathname } = location;
  const [show, set_show] = useState(false);
  const show_class = show ? "show" : "";


  const on_collapse = () => set_show(!show);

  /*const CollapsableNavItem = (props) => {
    const { eventKey, title, icon, children = null } = props;
    const default_key = pathname.indexOf(eventKey) !== -1 ? eventKey : "";

    return (
      <Accordion as={Nav.Item} defaultActiveKey={default_key}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className="d-flex justify-content-between align-items-center">
            <span>
              <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span>
              <span className="sidebar-text">{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className="multi-level">
            <Nav className="flex-column">
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };*/

  /**
   * @method NavItem
   * @description This is a componet which is responsible to render each menu item.
   * @param {String} title 
   * @param {String} link 
   * @param {Boolean} external
   * @param {String} target
   * @param {String} icon
   * @param {String} image
   * @param {String} badgeText
   * @param {String} badgeBg default value "secondary"
   * @param {String} badgeColor default value "primary"
   * @returns HTML dom
   */
  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = "secondary", badgeColor = "primary" } = props;
    const class_names = badgeText ? "d-flex justify-content-start align-items-center justify-content-between" : "";
    const nav_item_class_name = link === pathname ? "active" : "";
    const link_props = external ? { href: link } : { as: Link, to: link };

    return (

      <Nav.Item className={nav_item_class_name} onClick={() => set_show(false)}>
        <Nav.Link {...link_props} target={target} className={class_names}>
          <span>
            {icon ? <span className="sidebar-icon"><FontAwesomeIcon icon={icon} /> </span> : null}
            {image ? <Image src={image} width={20} height={20} className="sidebar-icon svg-icon" /> : null}
            <span className="sidebar-text">{title}</span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className="badge-md notification-count ms-2">{badgeText}</Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  /**
 * @method get_product_menu
 * @description This methode prepare array of HTML dom for cutomer products. This method match the customer product with supported product in const and prepare action only for matched items
 * @param {Array} customer_products 
 * @returns Array of HTML Dom
 */
  const get_product_menu = (customer_products) => {
    //console.log('side bar rendering');
    let productmenus = [];
    gt_constant.GT_PRODUCTS.forEach(pro => {
      const prodexist = customer_products.find(p => p.product_group_name == pro);
      if (prodexist) {

        const prod =gt_constant.GT_PRODUCT_CONFIG[pro];
        let key = prod.product_group_name + "_" + Date.now();

        switch (prod.product_group_name) {
          case 'Vegetation':
            productmenus.push(<div key={key}><NavItem title={prod.display_name} link={Routes.Vegetation.path} icon={faLeaf} /></div>);
            break;
          case 'LULC':
            productmenus.push(<div key={key}><NavItem title={prod.display_name} link={Routes.LandCover.path} icon={faMap} /></div>);
            break;
          case 'Water':
            productmenus.push(<div key={key}><NavItem title={prod.display_name} link={Routes.Water.path} icon={faWater} /></div>);
            break;
          case 'Atmospherics':
            productmenus.push(<div key={key}><NavItem title={prod.display_name} link={Routes.Atmospheric.path} icon={faCloud} /></div>);
            break;
          case 'Social':
            productmenus.push(<div key={key}><NavItem title={prod.display_name} link={Routes.Social.path} icon={faCity} /></div>);
            break;
          case 'Biodiversity':
            productmenus.push(<div key={key}><NavItem title={prod.display_name} link={Routes.Biodiversity.path} icon={faDna} /></div>);
            break;
        }
      }


    });
    return productmenus;
  }
  return (
    <div data-testid='ggsidemenu' key='ggproductmenu'>
      <Navbar expand={false} collapseOnSelect variant="dark" className="navbar-theme-primary px-4 d-md-none">
        <Navbar.Brand className="me-lg-5" as={Link} to={Routes.DashboardOverview.path}>
          <Image data-testid='ggcompanybrand' className="img-fluid mt-4" src={GTLogo} height={35} />
          {/* <img src="https://globaltrustgrp.com/wp-content/uploads/2020/12/GlobalTrust_Colour_CYMK-01_SSC-Company_2.jpg" className="img-fluid mt-4" alt="GlobalTrust"  height="35" /> */}
        </Navbar.Brand>
        <Navbar.Toggle as={Button} aria-controls="main-navbar" onClick={on_collapse}>
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${show_class} sidebar d-md-block bg-primary text-white`}>
          <div key='ggproductmenu1' className="sidebar-inner px-4 pt-3">

            <Nav className="flex-column pt-3 pt-md-0">
              <div key="logo" style={{ height: 108 }}>
                <a href="#">
                  <Image className="img-fluid mt-4" src={GTWHITELogo} height={35} />
                </a>
              </div>
              {/* <NavItem title="Global Guard" link={Routes.Presentation.path} image={ReactHero} /> */}

              <NavItem title="Overview" link={Routes.DashboardOverview.path} icon={faHome} />
              {get_product_menu(props.products)}

            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </div>
  );
};
export const Sidebar = React.memo(Sidebar1);