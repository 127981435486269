
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Form, Button, InputGroup, Image } from '@themesberg/react-bootstrap';
import GTPreLoading from '../../components/GTPreLoading';
import { sign_in } from "../../business/auth/AWSAuth";
import GTLogo from '../../assets/img/dashboard/GlobalTrust_Colour_CYMK-01_SSC-Company_2.jpg';
/**
 * @module GTSignin
 * @description This component renders a sign-in form. When a user provides authentication information, it is validated with the AWS Cognito user. Upon successful validation, it triggers the login_success callback.
 *
 * Parameters: 
 *  - function login_success, this function will be invoked when authentication successfull
 * 
 * @example
 * 
 * <GTSignin login_success={login_success} />
 *
 * @author Samaresh
 */
export default (props) => {
  /*Depending on this flag value it shows the progress bar*/
  const [authentication_in_progress, set_authentication_in_progress] = useState(false);
  /*If any error occurd during authentication process it set to this satet variable to render in UI*/
  const [error, set_error] = useState('');
  /*State variavle for user name*/
  const [user_name, set_user_name] = useState('');
  /* State variable for password*/
  const [password, set_password] = useState('');

  /**
   * @method validate_user
   * @description This method is invoked when the user clicks on the "Sign In" button. Before execution, it checks for the required information. If any required information is missing, it displays an error. Upon successful authentication, it invokes the callback function. To avoid multiple click during authenticaiton process execute it hide the sign in button
   */
  const validate_user = async () => {
    //var user_name = 'suresh.andem@globaltrustgrp.com';
    //var password = 'Metabase#123@G1oba15rust';
    let validation = [];
    if (user_name == null || user_name == '' || user_name.trim() == '') {
      validation.push(<li key='user_name'>Please provide user email</li>);
    }
    if (password == null || password == '' || password.trim() == '') {
      validation.push(<li key='password'>Please provide password</li>);
    }
    if (validation.length > 0) {
      set_error(validation);
      return;
    }
    set_error(validation);
    set_authentication_in_progress(true);

    try {
      await sign_in(user_name, password).then((result) => {
        props.login_success(result);
      },
        (error_restult) => {
          //console.log('error_restult=',error_restult)
          set_error(<li>Fail To Authenticate : {error_restult.message}</li>);
        }
      );
      set_authentication_in_progress(false);
      // Redirect to the app's main page or dashboard
    } catch (err) {
      //console.log('err=',err)
      set_authentication_in_progress(false);
      set_error(<li>Fail To Authenticate : {err.message}</li>);
    }
  }
  return (

    <main>
      <div className="bg-white shadow-soft border rounded border-light mb-4 p-4 p-lg-5 w-100 fmxw-500">
        <div className="text-center text-md-center mb-4 mt-md-0">
          <Image alt="logo" className="loader-element animate__animated animate__jackInTheBox" src={GTLogo} height={35} />
        </div>
        <div className="mt-4 mb-2">
          <Form.Group id="email" className="mb-4">
            <Form.Label>Your Email</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faEnvelope} />
              </InputGroup.Text>
              <Form.Control value={user_name} onChange={(e) => { set_user_name(e.target.value) }} autoFocus required type="email" placeholder="example@company.com" />
            </InputGroup>
          </Form.Group>
          <Form.Group>
            <Form.Group id="password" className="mb-4">
              <Form.Label>Your Password</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faUnlockAlt} />
                </InputGroup.Text>
                <Form.Control value={password} onChange={(e) => { set_password(e.target.value) }} required type="password" placeholder="Password" />
              </InputGroup>
            </Form.Group>
          </Form.Group>
          {authentication_in_progress ? (<GTPreLoading />) : (<Button variant="primary" className="w-100" onClick={validate_user}>Sign in</Button>)}
        </div>

        <div style={{ color: 'red' }}>
          <ul>
            {error}
          </ul>

        </div>

      </div>
    </main>
  );
};
