
import React, { useEffect, useState, useRef } from 'react';
//import vegaoi from "../../assets/img/dashboard/control_veg_AOI.png";
//import wateraoi from "../../assets/img/dashboard/Beach.png";
//import { dashboard_config } from '../../business/entity/DashboardSheetConfig';
import GTImage from '../../components/GTImage';
import GTMap from '../../components/GGMaps/GTMap';
import GTTableau from '../components/GTTableau';
import { gt_constant } from '../../business/constanct/gtconstant';
//import SessionService from '../../business/session/sessionmanagement';
//import DataService from '../../business/apigateway/service';
//import { get_idtoken, get_access_token, get_company_id } from '../../business/auth/AWSAuth';

/**
 * @module GlobalGurdDashboard
 * @description This component serves as a wrapper, encompassing an image, map, and Tableau dashboard component.
 *
 * @example
 *
 * <GlobalGurdDashboard dashboard="Vegetation" aoiid={123} sites={selectedsites} productimages={siteimages} wmscapability={[]} wmsworkspace={`cus_1_site_123`}  />
 *
 * @author Samaresh
 */
const GlobalGurdDashboard = (props) => {
  //let vizRef = useRef(null);
  const [current_dashboard, set_current_dashboard] = useState(props.dashboard);
  //const [curret_aoi_id, set_curret_aoi_id] = useState(props.aoi_id);
  //const [shouldchangeurl, setshouldchangeurl] = useState(true);
  //const [shouldregister, setshouldregister] = useState(true);
  //const [tableautokenavailable, settableautokenavailable] = useState(false);
  //let gtdashboard = null;
  //let filterapplied = false;




  // const handleFirstInteractive = async (firsthandleobj) => {
  //   if (!filterapplied) {
  //     filterapplied = true;
  //   }
  // }

  
  // const loadtabletoken = (isnew) => {
  //   let company_id = get_company_id();
  //   let idtoken = get_idtoken();
  //   let dataservice = new DataService(idtoken, get_access_token());
  //   if (idtoken && company_id != -1) {
  //     dataservice.post(gt_constant.GT_API_URL, gt_constant.GT_API_TABLEAU_TOKEN).then((res) => {
  //       if (res && res.token) {
  //         SessionService.set_session(gt_constant.TABLEAU_TOKEN_SESSION_KEY, res.token);
  //         if (isnew) {
  //           settableautokenavailable(true);
  //         }
  //       }

  //     }).catch(error=>{
  //       if(!isnew){
  //         window.location = '/';
  //       }
  //     });
  //   }

  //   setTimeout(() => {
  //     loadtabletoken(false);
  //   }, 1000 * 2 * 60);
  // }
  useEffect((obj) => {

    //SessionService.remove_session(gt_constant.TABLEAU_TOKEN_SESSION_KEY);
    //loadtabletoken(true);
  }, [])

  // useEffect((obj) => {

  //   //if (vizRef && vizRef.current && shouldchangeurl) 
  //   {
  //     //setshouldchangeurl(false);
  //     /*
  //     gtdashboard = document.getElementById("tableauViz");
  //     if (shouldregister) {
  //       gtdashboard.addEventListener("firstinteractive", handleFirstInteractive);
  //       setshouldregister(false);
  //     }
  //     setshouldchangeurl(false);
  //     */
  //     //checkworksheet();

  //   }
  // }, [vizRef, current_dashboard, curret_aoi_id])

  if (current_dashboard != props.dashboard) {
    //setshouldchangeurl(true);
    set_current_dashboard(props.dashboard);
  } 
  // else if (props.aoi_id != -1 && curret_aoi_id != -1 && props.aoi_id != curret_aoi_id) {
  //   try {
  //     //set_curret_aoi_id(props.aoi_id);
  //   }
  //   catch (ex) {
  //     console.error(ex);
  //   }

  // }
  // const getdashboardComponent = () => {

  //   let aoilist = [props.aoi_id];
  //   const dashconfig = dashboard_config[props.dashboard];
  //   if (dashconfig == null) {
  //     return null;
  //   }
  //   const getExtraFilers = () => {
  //     switch (props.dashboard) {
  //       case 'Social':
  //         return <viz-filter field="aoi_name" value={props.sites.map(s => s.aoi_name)} />
  //         break;
  //       default:
  //         return null;
  //     }
  //   }

  //   const d = new Date();

  //   let checkworksheet = () => {
  //     try {
  //       if (vizRef.current.workbook && vizRef.current.workbook.activeSheet && vizRef.current.workbook.activeSheet.worksheets) {
  //         dashboard_config[props.dashboard].Sheets.forEach(async (sheetconfig, index) => {
  //           let sheet = vizRef.current.workbook.activeSheet.worksheets.find(sheet => sheet.name === sheetconfig.sheet);
  //           sheetconfig.Filters.forEach(async (filter, index) => {
  //             let filterFieldName = filter.filterFieldName;
  //             let filterValue = [0];
  //             if (filter.filterValueFromCurrent) {
  //               filterValue = [props.aoi_id];
  //             }
  //             if (filter.filterValueFromAllAOI) {
  //               filterValue = props.sites.map(s => s.aoi_id);
  //             }
  //             if (filter.filterValue) {
  //               filterValue = [filter.filterValue];
  //             }
  //             if(sheet){
  //               await sheet.applyFilterAsync(filterFieldName, filterValue, 'replace', false).then((res) => {

  //             }).catch(ex=>{
  //               throw new Error("not found sheet try again"); 
  //             });
  //             }else{
  //               throw new Error("not found sheet try again"); 
  //             }
              
  //           });
  //         });


  //       }
  //       else {
  //         setTimeout(() => {
  //           checkworksheet();
  //         }, 500);
  //       }
  //     } catch (ex) {
  //       setTimeout(() => {
  //         checkworksheet();
  //       }, 500);
  //     }
  //   }
  //   /*if(dashboard_config[props.dashboard].Sheets && dashboard_config[props.dashboard].Sheets.length>0){
  //     setTimeout(() => {
  //       checkworksheet();
  //     }, 500);
  //   }*/
  //   if (vizRef && vizRef.current) {
  //     //vizRef.current.dispose();


  //   }
  //   //width={dashconfig.width + 5}
  //   let tableeautoken=SessionService.get_session(gt_constant.TABLEAU_TOKEN_SESSION_KEY);
  //   if(tableeautoken){
  //      return (
  //     <tableau-viz height={dashconfig.height} width={dashconfig.width + 5} id="tableauViz" hide-tabs toolbar='hidden'
  //       src={gt_constant.GT_TABLEAU_SITE+ dashconfig.url + '?t=' + d.getTime()}
  //       token={tableeautoken}
  //       ref={vizRef}>
  //       <viz-filter field="customer_id" value={get_company_id()} />
  //       <viz-filter field="aoi_id" value={aoilist} />
  //       {getExtraFilers()}
  //       {setTimeout(() => {
  //         checkworksheet();
  //       }, 500)}
  //     </tableau-viz>
  //   );
  //   }else{
  //     return null;
  //   }
   
  // }

  /**
   * @method render_images
   * @description This method filter images by aoi id and prepare list of image dom.
   */
  const render_images = (images) => {
    let image_list = [];
    //imagelist?.filter(im => im.aoi_id == curret_aoi_id)?.map((val, index) => {
      images?.filter(im => im.aoi_id == props.aoi_id)?.map((val, index) => {
      image_list.push(<div key={'div_Image_Com_' + val.aoi_id + '_' + val.product_name} style={{ marginTop: 10 }} className='imageboader'>
        <GTImage key={'img_Image_Com_' + val.aoi_id + '_' + val.product_name} caption={val.product_description} url1={val.product_url} /></div>);
    });
    return image_list;
  }
  const first_col_class = (props.dashboard == 'LULC') ? 'col col-md-12' : 'col col-md-10';
  //var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(claimSet));
  /**
   * @method render_right_col_content
   * @description Product wise we have different layout. This method render the right column content for each produt.
   */
  const render_right_col_content = () => {
   
    switch (props.dashboard) {
      case 'Vegetation':
      case 'Water':
        return (<div className='col col-md-2' style={{ paddingTop: 8 }}>
          <div style={{ marginTop: 10 }}><GTMap map_from_ext={true} wmscapability={props.wmscapability} wmslayerprefix={props.wmsworkspace+':'+gt_constant.GT_DASHBOARD_CONFIG[props.dashboard].wmslayerprefix}  polygon={props.sites?.find(s => s.aoi_id==props.aoi_id)?.location}></GTMap></div>
          {render_images(props.productimages)}
        </div>);     
      case 'LULC':
        return (<div className='col col-md-12' style={{ marginTop: '-50px' }}>
          {render_images(props.productimages)}
        </div>);
      default:
        return (<div className='col col-md-2' style={{ paddingTop: 8 }}>
        {render_images(props.productimages)}
      </div>);  
    }
  }

  return (
    <div className="row" data-testid='ggmiddlecontent'>
      
      <div className={first_col_class}>
       {/*  <div id="gtdashboard" style={{ marginTop: '6px', width: dashboard_config.width + 10 }}>
          {getdashboardComponent()}
        </div> */}
        <GTTableau {...props}></GTTableau>
      </div>
      {render_right_col_content()}
    </div>

  );
};
export default GlobalGurdDashboard;