
import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polygon, Marker, Popup, WMSTileLayer, useMap, LayersControl, GeoJSON, ZoomControl } from 'react-leaflet';
import { Nav, Badge, Image, Modal, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import 'leaflet/dist/leaflet.css'; // Import Leaflet's CSS
import { BingLayer } from 'react-leaflet-bing-v2';

import { WMSTileLayerWithHeader } from './WMSTileLayerWithHeader';
import { get_idtoken } from '../../business/auth/AWSAuth';
import { gt_constant } from '../../business/constanct/gtconstant';
import GT3DMap from './GT3DMap';
import GTGlobalMap from './GTGlobalMap';
import GTSelectWMSLayers from './GTSelectWMSLayers';
import L from 'leaflet';
import * as turf from '@turf/turf';
import GTWMSLegend from './GTWMSLegend';

//import GT2DSideBySideMap from './GT2DSideBySideMap';
const { BaseLayer, Overlay } = LayersControl;
function get_layer_bound(Capability, currentlyer, needExBbox) {
    try {
        const layers = Capability?.Layer?.Layer;
        if (layers.length > 0) {
            let find_current_layer = (layerlist) => {
                let search_result = layerlist.filter((l) => l.Name === currentlyer);
                if (search_result && search_result.length > 0) {
                    return search_result[0];
                }
                search_result = null;
                layerlist.forEach((sublayer, index) => {
                    if (sublayer.Layer && sublayer.Layer.length > 0 && search_result == null) {
                        search_result = find_current_layer(sublayer.Layer);
                        if (search_result) {
                            return false;
                        }
                    }
                });
                return search_result;
            }
            const layer = find_current_layer(layers);
            const bbox = layer?.EX_GeographicBoundingBox;
            if (needExBbox) {
                return bbox;
            }
            return [[bbox[1], bbox[0]], [bbox[3], bbox[2]]];

        }
    } catch (ex) {

    }

}

function GTFixMap(props) {
    const map = useMap();
    //console.log('map center:',map, map.getCenter());
    if (props.show) {
        setTimeout(() => map.invalidateSize(), 100);
    }
}
function GTLeafletMapComponent(props) {

    const map = useMap()

    try {
        if (props.show && props.wmscapability) {
            const layers = props.wmscapability?.Capability?.Layer?.Layer;
            if (layers.length > 0) {
                const bbox = get_layer_bound(props.wmscapability?.Capability, props.layername);
                /*let find_current_layer = (layerlist) => {
                    let search_result = layerlist.filter((l) => l.Name === props.layername);
                    if (search_result && search_result.length > 0) {
                        return search_result[0];
                    }
                    search_result = null;
                    layerlist.forEach((sublayer, index) => {
                        if (sublayer.Layer && sublayer.Layer.length > 0 && search_result == null) {
                            search_result = find_current_layer(sublayer.Layer);
                            if (search_result) {
                                return false;
                            }
                        }
                    });
                    return search_result;
                }
                const layer = find_current_layer(layers);
                const bbox = layer?.EX_GeographicBoundingBox;
                //const bbox=layer.BoundingBox[1].extent;
                console.log(bbox);

                const outerBounds = [
                    [50.505, -29.09],
                    [52.505, 29.09],
                ]
                const bbox=[[bbox[1], bbox[0]], [bbox[3], bbox[2]]]*/
                setTimeout(() => {
                    //map.fitBounds(outerBounds);
                    if (bbox && bbox.length > 0) {
                        map.fitBounds(bbox);
                    }
                }, 100);

            }
        }

    }
    catch (error) {
        console.error('Error request:', error);
    }

    //setTimeout(() => map.invalidateSize(), 100);
    return null
}
/**
 * @module GTMap
 * @description The react-leaflet npm package is employed to render the 2D map component. Initially, it displays a small view of the map with the selected area of interest (AOI) polygon. Upon clicking on the map, it expands to full screen and loads an image with available Web Map Service (WMS) information. If there is no WMS layer available, it only displays the polygon.
 * 
 * Parameters
 *  - wmscapability
 *  - wmslayerprefix
 *  - polygon
 * 
 * @example 
 * 
 * <GTMap wmscapability={props.wmscapability} wmslayerprefix={prefix}  polygon={polygon}></GTMap>
 * 
 * @author Samaresh
 */
const GTMap = (props) => {

    const [show_default, set_show_default] = useState(false);
    const [show_select_layer, set_show_select_layer] = useState(false);
    const [layer_name, set_layer_name] = useState(null);
    const [map_type, set_map_type] = useState('2d');
    const [map_catalogue, set_map_catalogue] = useState(null);

    const handle_close = () => set_show_default(false);
    useEffect(() => {

        if (layer_name == null && props.wmscapability && props.wmscapability?.Capability
            && props.wmscapability?.Capability?.Layer && props.wmscapability?.Capability?.Layer?.Layer && props.wmscapability?.Capability?.Layer?.Layer?.length > 0) {

            let layer_list = {};
            props.wmscapability?.Capability?.Layer?.Layer?.map((val, index) => {
                let catalog = {
                    visible: true,
                    layer_name: val.Name,
                    ws_name: val.Name.split(':')[0],
                    compare: false,
                    info: '',
                    opacity: 1,
                    //bounday = bounday;
                    title: val.Title,
                    added: index == 0 ? true : false
                };
                if (layer_list[catalog.ws_name] == null) {
                    layer_list[catalog.ws_name] = [];
                }
                layer_list[catalog.ws_name].push(catalog);
            });
            set_layer_name(props.wmscapability?.Capability?.Layer?.Layer[0].Name);
            set_map_catalogue(layer_list);
        }
        return () => {

        }
    }, [])
    //https://react-leaflet.js.org/docs/api-map/
    const get_headers = () => {
        return {
            Authorization: get_idtoken(), // Replace with your actual access token or authorization method
            //requestsource:'gtlocaloffice'
        };
    }

    if (props.map_from_ext && layer_name && layer_name != props.wmscapability?.Capability?.Layer?.Layer[0].Name) {
        set_layer_name(props.wmscapability?.Capability?.Layer?.Layer[0].Name);
    }
    // Define the center and zoom level for the map
    const position = [51.505, -0.08];
    const zoom = 7;

    const calculate_polygon_center = (coords) => {
        if (coords == null || coords.length === 0) {
            return null;
        }

        let sum_lat = 0;
        let sum_lng = 0;
        let area = 0;
        /*
        let area = 0;

    for (let i = 0; i < vertices.length; i++) {
        let j = (i + 1) % vertices.length;
        area += (vertices[i].x + vertices[j].x) * (vertices[i].y - vertices[j].y);
    }

    area = Math.abs(area) / 2;
    return area;
        */
        coords.forEach((coord,i) => {
            sum_lat += coord[0];
            sum_lng += coord[1];

            const nextVertex = coords[(i + 1) % coords.length];
        area += (coord[0] + nextVertex[0]) * (coord[1] - nextVertex[1]);
        });

        const center_lat = sum_lat / coords.length;
        const center_lng = sum_lng / coords.length;
        area = Math.abs(area) / 2;
        return [[center_lat, center_lng],area];
    }

    const bing_key = gt_constant.GT_BING_KEY;
    /*const get_layer_legend_url = () => {
        var wmslayer = props.wmscapability?.Capability?.Layer?.Layer?.filter(f => f.Name == layer_name);
        if (wmslayer && wmslayer.length > 0 && wmslayer[0].Style && wmslayer[0].Style.length > 0 && wmslayer[0].Style[0].LegendURL && wmslayer[0].Style[0].LegendURL.length > 0) {
            return wmslayer[0].Style[0].LegendURL[0].OnlineResource;
        } else {
            return null;
        }
    };*/
    const renderlayernames = () => {
        let layers = [];
        let rgbprifix = `${props.wmslayerprefix.split(':')[0]}:rgb_`;
        let filteredlayers = props.wmscapability?.Capability?.Layer?.Layer?.filter(f => f.Name.indexOf(props.wmslayerprefix) == 0);
        if (filteredlayers && filteredlayers.length > 0) {
            filteredlayers.sort((a, b) =>
                a.Title.toLowerCase().localeCompare(b.Title.toLowerCase()));
        } else {
            filteredlayers = [];
        }
        let filteredRGBlayers = props.wmscapability?.Capability?.Layer?.Layer?.filter(f => f.Name.indexOf(rgbprifix) == 0);
        if (filteredRGBlayers && filteredRGBlayers.length > 0) {
            filteredRGBlayers.sort((a, b) =>
                a.Title.toLowerCase().localeCompare(b.Title.toLowerCase()));
            filteredlayers = filteredlayers.concat(filteredRGBlayers);
        }
        if (filteredlayers && filteredlayers.length > 0) {

            if (filteredlayers && filteredlayers.filter(f => f.Name == layer_name).length == 0) {
                set_layer_name(filteredlayers[0].Name);
            }
            const fnlayerlist = () => {

                filteredlayers.map((val, index) => {
                    let classname = val.Name != layer_name ? 'wmslayername bg-dark bg-gradient text-white' : 'wmslayername bg-info bg-gradient text-white';
                    layers.push(<option key={val.Name} value={val.Name} title={val.Title}>{val.Title}</option>);
                });
                return layers;
            }


            return (
                <>
                    {/*  <button className="btn btn-tertiary  me-5 float-lg-end" type="button" onClick={(e)=>{
                    e.stopPropagation();
                    e.preventDefault();
                    set_show_select_layer(true)}}>Explore map data</button>
                {show_select_layer && <GTSelectWMSLayers show_layers={show_select_layer} show={show_select_layer} layer_list={map_catalogue} 
                selection_callback={(updated_layers)=>{  
                    console.log(updated_layers)   ;               
                    set_show_select_layer(false)}
                    } />} */}
                    Map Layer: <select onChange={(e) => {
                        set_layer_name(e.target.value);
                    }}>
                        {fnlayerlist()}
                    </select>
                </>
            )
        }
        else {
            if (layer_name) {
                set_layer_name(null);
            }

        }

        return layers;
    }

    const render_aoi = (mapheight) => {
        let polygon_coords = [];
        if (props.polygon) {
            //polygon_coords = JSON.parse(props.polygon.replace(/\(/g, '[').replace(/\)/g, ']'));
            polygon_coords = JSON.parse(props.polygon);
        }
        let aoizoom = 11;
        const revcoordinates = polygon_coords.map(coordinate => {
            return [coordinate[1], coordinate[0]]; // Swap lat and lng
        });
        const aoiposition = calculate_polygon_center(revcoordinates);
        if(aoiposition[1]*1000>0.07){
            let diff=aoiposition[1]*1000-0.07;
            aoizoom=Math.round(aoizoom-diff) ;
        }
        //console.log('area=',aoiposition[1]*1000)

        return (
            <MapContainer
                center={aoiposition[0]}
                zoom={aoizoom}
                zoomControl={false}
                attributionControl={false}
                style={{ height: mapheight }}
                key={aoiposition}
            //crs='EPSG:4326'


            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {/* <BingLayer bingkey={bing_key} />  */}
                <BingLayer bingkey={bing_key} type="AerialWithLabels" />


                <GTFixMap show={show_default} />
                <Polygon positions={revcoordinates} color="orange" fillOpacity={0.3} />
                {/* <GeoJSON data={geojsonData} /> */}
                {/* <Polygon positions={polygon_coords} color="blue" fillOpacity={0.5} /> */}

            </MapContainer>
        )
    }
    //https://github.com/Charmatzis/react-leaflet-bing/blob/master/dist/react-leaflet-bing.js
    let showinternalmap = props.wmscapability?.Capability && !layer_name;
    return (
        <div className="row">

            <div className='col col-md-12'>

                <div className='roundbox border-1 bg-soft shadow' onClick={() => { set_show_default(true); }}>
                    <center><span style={{ fontSize: '16px', fontWeight: 'bold', padding: '8px' }}>Location Map</span></center>

                    <div>
                        {render_aoi("300px")}
                    </div>

                </div>

                <Modal as={Modal.Dialog} size='lg' fullscreen={true} centered show={show_default} onHide={handle_close}>

                    <Modal.Header>
                        {/* <Modal.Title className="h6">Map</Modal.Title> 

                        {show_default && layer_name ? <GTWMSLegend layername={layer_name} /> : null}*/}
                        {props.map_from_ext && !showinternalmap ? <Modal.Title className="h6">Map</Modal.Title> : <>
                            <div style={{ position: 'absolute' }}>
                                Map Type: <select onChange={(e) => {
                                    set_map_type(e.target.value);
                                }} >
                                    {map_type == '2d' ? <option selected value='2d'>2D</option> : <option value='2d'>2D</option>}
                                    {map_type == '3d' ? <option selected value='3d'>3D</option> : <option value='3d'>3D</option>}
                                </select>
                            </div>
                            <div style={{ marginLeft: '145px' }}>{renderlayernames()}</div>
                            {!props.wmscapability?.Capability ? <span style={{ color: 'red' }}>Map server is not available</span> : props.wmscapability?.Capability && !layer_name ? <span style={{ color: 'red' }}>No WMS layer available for this site</span> : null}

                        </>}

                        {/*  <button type='button' onClick={()=>{set_map_type('2dcompare')}}>Compare</button> */}
                        <Button variant="close" aria-label="Close" style={{ marginRight: '20px', border: 'solid 3px red' }} onClick={handle_close} />

                    </Modal.Header>
                    <Modal.Body>

                        {props.map_from_ext && !showinternalmap ? <GTGlobalMap wmslayerprefix={props.wmslayerprefix} bbox={get_layer_bound(props.wmscapability?.Capability, layer_name, true)} /> : map_type == '3d' ? <div key='my3dmap'><GT3DMap layername={layer_name} bbox={get_layer_bound(props.wmscapability?.Capability, layer_name, true)} /></div> : layer_name && map_type == '2d' ?
                            <MapContainer
                                //center={[51.5072, 0.1276]}
                                center={position}
                                zoom={zoom}
                                style={{ height: "90vh" }}
                            >

                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                {/* <BingLayer bingkey={bing_key} />  */}

                                <GTFixMap show={show_default} />
                                {
                                    layer_name ? <GTLeafletMapComponent wmscapability={props.wmscapability} layername={layer_name} show={show_default} /> : null
                                }
                                <LayersControl position='topright'>
                                    <BaseLayer name='OpenStreetMap.Mapnik'>
                                        <TileLayer url="http://{s}.tile.osm.org/{z}/{x}/{y}.png" />
                                    </BaseLayer>
                                    <BaseLayer checked name='Bing Maps Roads'>
                                        <BingLayer bingkey={bing_key} type="Road" />
                                    </BaseLayer>
                                    <BaseLayer checked name='Bing Maps Satelite'>
                                        <BingLayer bingkey={bing_key} />
                                    </BaseLayer>
                                    <BaseLayer checked name='Bing Maps Satelite with Labels'>
                                        <BingLayer bingkey={bing_key} type="AerialWithLabels" />
                                    </BaseLayer>
                                    <Overlay checked name="WMS Layer">
                                        {layer_name ? (
                                            <WMSTileLayerWithHeader
                                                key={layer_name}
                                                url={gt_constant.GEOSERVER_WMS_URL + '/wms'}
                                                layers={layer_name}
                                                format="image/png"
                                                transparent={true}
                                                bounds={get_layer_bound(props.wmscapability?.Capability, layer_name)}
                                                tiled={true}
                                                headers={get_headers()}
                                                version="1.1.0"
                                                opacity={1}
                                            //styles="NDVI"
                                            />
                                        ) : null}
                                    </Overlay>
                                </LayersControl>
                                <ZoomControl position="bottomright" />
                            </MapContainer> : layer_name && map_type == '2dcompare' ? {/* <GT2DSideBySideMap get_headers={get_headers} position={position}  zoom={zoom}></GT2DSideBySideMap> */ } : <div>{render_aoi("90vh")}</div>}
                    </Modal.Body>
                    {/*  <Modal.Footer style={{display:'block'}}>
                    {show_default && layer_name ? <GTWMSLegend layername={layer_name} /> : null}
                    </Modal.Footer>  */}
                </Modal>

            </div>

        </div>

    );
};
export default GTMap;