
import React, { useEffect, useState, useRef } from 'react';

import { Nav, Badge, Image, Modal, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { useNavigate } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import GTSignin from '../pages/components/GTSignin';
import { get_current_user, signOut } from '../business/auth/AWSAuth';
/**
 * @module TopBanner
 * @description This component oversees the rendering of buttons in the top banner, specifically for signing out and accessing the dashboard. When a user is logged in, the browser displays both a "Sign out" and a "Dashboard" button. Clicking on the "Sign out" button allows the user to log out. If the user clicks on the "Dashboard" button, it will either display the sign-in window or redirect to the overview page, depending on the user's login status.
 *
 * @example
 * 
 * <GTDashboardButton />
 *
 * @author Samaresh
 */
const GTDashboardButton = (props) => {

    //This state variable contains flag wheather sign in modal show or hide
    const [show_default, set_show_default] = useState(false);
    // This sate variable contains current logged in user information
    const [user_data, set_user_data] = useState(null);

  /**
   * @method handle_close
   * @description Invoked when modal needs to close.
   */
    const handle_close = () => set_show_default(false);
    /*const navigate = useNavigate();
       const route_change = () =>{ 
         let path = `/dashboard/overview`; 
         navigate(path);
       }
    const iconstyle = { fontSize: '17px', color: 'red', fontWeight: '100px' };*/
    const history = useHistory();
   /**
   * @method route_change
   * @description This method invoked when user click on Dashboard button.
   * If found user logged in to navigare the user to overview page.
   * Otherwise show sign in popup
   */
    const route_change = async () => {
        /*let path = `/dashboard/overview`; 
        history.push(path);*/
        //signOut();
        if (user_data == null) {
            set_show_default(true);
        } else {
            get_current_user()
            login_success(user_data);
        }
    }
  /**
   * @method login_success
   * @description This method is passed to the signin component as a parameter. Upon a successful signin, it is invoked to navigate to the overview page.
   */
    const login_success = (user) => {
        let path = `/dashboard/overview`;
        try {
            history.push(path);
            //window.location=path;
        } catch (Ex) {

        }
    }
  /**
   * @method useEffect
   * @description A hook method that executes only during the initial rendering of the component is implemented here. In this method, we check whether any user is logged in or not and set the set_user_data accordingly. Based on this value, the decision is made on whether the "Sign Out" button will be displayed or not.
   */
    useEffect(() => {
        try {
            get_current_user().then(u => {
                set_user_data(u);
                //console.log(u);
            }).catch((e) => {
                //console.log(e);
            });


        } catch (err) {
            //console.log(err);
        }
    }, [])
    return (
        <div data-testid='gt-dashboard-button'>
            {user_data != null ? <button data-testid='btnsignout' className="btn btn-danger mt-lg-n5 me-9 float-lg-end" type="button" onClick={() => { signOut(); window.location = '/'; }}>
                <FontAwesomeIcon icon={faPowerOff} className="me-1" /> Sign out</button> : null}


            <button className="btn btn-tertiary mt-lg-n5 me-5 float-lg-end" type="button" onClick={route_change}>Dashboard</button>
            {show_default ? (
                <Modal as={Modal.Dialog} size='md' centered show={show_default} onHide={handle_close}>
                    <Modal.Header>
                        <Modal.Title className="h6">Sign In</Modal.Title>
                        <Button variant="close" aria-label="Close" onClick={handle_close} />
                    </Modal.Header>
                    <Modal.Body>
                        <GTSignin login_success={login_success} />
                    </Modal.Body>
                    {/*  <Modal.Footer>
                    
                    <Button variant="link" className="text-gray ms-auto" onClick={handle_close}>
                        Close
                    </Button>
                </Modal.Footer> */}
                </Modal>
            ) : null}

        </div>

    );
};
export default GTDashboardButton;