
import React from 'react';
//import GaugeChart from "react-gauge-chart";
import GaugeChart from "./ReactGaugeLib/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faArrowAltCircleDown,faArrowAltCircleUp,faArrowUp,faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import SessionService from '../business/session/sessionmanagement';
import { Routes } from "../routes";
/**
 * @module GTGauge
 * @description This component is tasked with rendering a gauge chart with two needles. 
 * To enable the support for two needles, the component extends the functionality of the <b>react-gauge-chart</b> library by incorporating additional code. 
 * Within this extension, a new method, <b>drawSecondNeedle</b>, has been introduced.
 * 
 * Parameters:
 *  - minH Numberc
 *  - percent Decimal value 
 *  - oldpercent Decimal value 
 *  - info String
 *  - subinfo String
 *  - description String which will show as tooptip
 *  - changetype Number value can be -1, 0, 1
 * 
 * @example
 * <GtGauge minH={200} percent={newvalue} oldpercent={oldvalue} info={info} subinfo={subinfo} description={description} changetype={change_type} />
 * 
 * @author Samaresh
 */
const GTGauge = (props) => {
    // The essential CSS is specified within this wrapper, and there's no need for various support requirements.
    const styles = {
        dial: {
            display: "inline-block",
            width: `100%`,
            height: `auto`,
            color: "#000",
            /*border: "0.5px solid #fff",*/
            padding: "2px",
            overflow: 'auto'
        },
        "text-group": {
            background: 'red'
        },
        title: {
            fontSize: "18px",
            color: "#0051a5",
            textAlign: 'center',
            cursor:'pointer'
        },
        subtitle: {
            fontSize: '11px',
            color: '#fff',
            textAlign: 'center',
            background: 'gray',
            borderRadius: '8px',
            padding: '2px'
        },
        description: {
            fontSize: "11px",
            color: "gray",
            textAlign: 'center',
        },
        valueclass: {
            color: '#fff',
            width: `71%`,
            margin: '0 auto',
            padding: '1px',
            background: '#000',
            textAlign: 'center',
            borderRadius: '20px',
            fontSize: '20px',
            marginTop: '-10px',
            border: "0.5px solid #fff",
        },
        indicatorclass:{
            width: `68%`,
            margin: '0 auto',
            marginTop: '10px',
        },
        centerdot:
        {
            color: '#fff',
            margin: '0 auto',
            width: `30%`,
            fontSize: '30px',
        },
        iconstylered:{
            fontSize: '18px', color: 'white', float:'right',marginTop:'-44px',background:'red',borderRadius:'50%',padding:'2px'
        },
        iconstylegreen:{
            fontSize: '18px', color: 'white', float:'right',marginTop:'-44px',background:'green',borderRadius:'50%',padding:'2px'
        }

    };
    const history = useHistory();

    /**
   * @method navigate_to_dashboard
   * @description When user click on the link to navigate to the relevent dashboard. That mean when user click on the gauge for water site1, 
   * it will navigate to water dashboard and site1 will be selected. When user click on gauge we can identify the dashboard by product name, 
   * and redirect to dashboard by product name, set the AOI id in session to make it selected. That session value will use in SiteMenu component for default selection.
   */
    const navigate_to_dashboard = () => {
        let dash_path='';
        switch(props.product_group){
            case 'Vegetation':
                dash_path=Routes.Vegetation.path;
              break;
            case 'LULC':
                dash_path=Routes.LandCover.path;
              break;
            case 'Water':
                dash_path=Routes.Water.path;
              break;
            case 'Atmospherics':
                dash_path=Routes.Atmospheric.path;
              break;
            case 'Social':
                dash_path=Routes.Social.path;
              break;
            case 'Biodiversity':
                dash_path=Routes.Biodiversity.path;
              break;
          }
        try {
            SessionService.set_session('selected_aoi',props.aoi_id);
            history.push(dash_path);
        } catch (Ex) {

        }
    }

    return (
        <div  style={{ minHeight: props.minH }} className="card card-body border-0 bg-soft shadow mb-4 mb-xl-0">
            <div  style={styles.dial}>
                <GaugeChart
                
                    id={props.id}
                    nrOfLevels={6}
                    colors={["#ff6767", "#16e041"]}
                    arcWidth={0.7}
                    percent={props.percent}
                    oldpercent={props.oldpercent}
                    textColor={"#000000"}
                    formatTextValue={(value) => ""}
                    changetype={props.changetype}
                />
                
                <div title={props.description} style={styles.valueclass}>                
                    <div>{(props.percent * 100).toFixed(0)}%</div>                     
                    <div style={styles.description}>{(props.oldpercent * 100).toFixed(0)}%</div>
                </div>
                {
                    props.changetype==0?null:(
                        <div style={styles.indicatorclass}>
                            <FontAwesomeIcon style={props.changetype==1? styles.iconstylegreen:styles.iconstylered} icon={props.changetype==1?faArrowUp: faArrowDown} className="me-1" />
                        </div>
                    )
                }
                
                <div  style={styles.title} onClick={navigate_to_dashboard} >{props.info}</div>
                <div style={styles.subtitle}>{props.subinfo}</div>
                <div style={styles.description}>{props.description}</div>
            </div>
        </div>
    );
};
export default GTGauge;