import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";
import GTLandingPage from './GTLandingPage';
import GlobalGuardOverview from "./dashboard/GlobalGuardOverview";
import GlobalGurdDashboard from "./dashboard/GlobalGurdDashboard";
import NotFoundPage from "./error/NotFound";
import ServerError from "./error/ServerError";
import NotFoundProduct from "./error/NotFoundProduct";

// components
import { Sidebar } from "../components/Sidebar";
import TopBanner from "../components/TopBanner";
import SiteMenu from "../components/SiteMenu";
import Preloader from "../components/Preloader";


import { get_company_id, get_session, get_idtoken, get_access_token } from '../business/auth/AWSAuth';
import DataService from '../business/apigateway/service';
import WMSCapabilities from 'wms-capabilities';
import { gt_constant } from '../business/constanct/gtconstant';
import GTCompanyAssociationError from '../components/GTCompanyAssociationError';
import GTDataServiceError from '../components/GTDataServiceError';
import SessionService from '../business/session/sessionmanagement';
import { useHistory  } from "react-router-dom";
/**
 * @module RouteWithLoader
 * @description This component render the component with preloader
 *
 * @example
 *
 * <RouteWithLoader exact path={Routes.GTIndex.path} component={GTLandingPage} />
 *
 * @author Samaresh
 */
const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, set_loaded] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => set_loaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => (<> <Preloader show={loaded ? false : true} /> <Component {...props} /> </>)} />
  );
};

/**
 * @module RouteWithSidebar
 * @description This component render the component with side bar
 *
 * @example
 *
 * <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={GlobalGuardOverview} />
 *
 * @author Samaresh
 */
const RouteWithSidebar = ({ component: Component, ...rest }) => {

  const [loaded, set_loaded] = useState(false);
  const [data_service_available, set_data_service_available] = useState(true);
  const [wms_layer, set_wms_layer] = useState(null);
  const [product_list, set_product_list] = useState(null);
  const [site_list, set_site_list] = useState(null);
  const [customer, set_customer] = useState(null);
  const [company_id, set_company_id] = useState(0);
  const [product_images, set_product_images] = useState(null);
  const history = useHistory();

  let selected_product = product_list ? product_list.find(p => p.product_group_name == rest.dashboard) : null;

  let sites_for_selected_product = selected_product ? site_list.filter(s => s.product_group_id == selected_product.product_group_id) : [];
  let selected_product_images = selected_product && product_images ? product_images.filter(s => s.product_group_id == selected_product.product_group_id) : [];
  if (selected_product && selected_product.product_group_name == 'Vegetation') {
    let forest_product = product_list ? product_list.find(p => p.product_group_name == 'Forest') : null;
    let forest_product_images = selected_product && product_images ? product_images.filter(s => s.product_group_id == forest_product.product_group_id) : [];
    selected_product_images = selected_product_images.concat(forest_product_images);
  }

  //const [aoi_id, set_aoi_id] = useState(-1);
  let initial_aoi = sites_for_selected_product && sites_for_selected_product.length > 0 ? sites_for_selected_product[0].aoi_id : -1
  const [aoi_id, set_aoi_id] = useState(initial_aoi);
  //console.log(window.location.pathname,window.location.hash);
  let user_id_token = get_idtoken();
  const service = new DataService(user_id_token, get_access_token());

  /**
   * @method useEffect
   * @description A hook method that executes when aoi_id state changed to load wms layer information
   */
  useEffect(() => {
    get_wms_layers();
  }, [aoi_id]);

  /**
   * @method useEffect
   * @description A hook method that executes only during the initial rendering of the component is implemented here. In this method, fist it check the user token and company association than  it load customer product, site, product images.
   */
  useEffect(() => {
    let company_id = get_company_id();
    if (user_id_token && company_id != -1) {
      get_company_products();
      get_company_info();
      get_products_images();
      if (wms_layer == null) {
        get_wms_layers();
      }
      set_loaded(true);
    } else if (company_id == -1) {
      set_company_id(-1);
    }

  }, []);

  /**
   * @method is_overview
   * @description This method check current request is for overview or not
   */
  const is_overview = () => {
    return window.location && window.location.hash && window.location.hash.indexOf("#/dashboard/overview") >= 0;
  }
  /**
   * @method get_wms_layers
   * @description This method load wms layers for selected prodcut and aoi
   */
  const get_wms_layers = () => {
    get_session().then((usersession) => {
    
      //const id_token = usersession.getIdToken.jwtToken;
      const id_token = usersession?.idToken?.jwtToken;
      const workspace = `cus_${get_company_id()}_site_${aoi_id}`;
      const wms_request_url = `${gt_constant.GEOSERVER_WMS_URL}${gt_constant.GEOSERVER_CAPABILITY_URL_PARAMETER}&${gt_constant.GEOSERVER_WMS_WORKSPACE_PROP}=${workspace}`;
      //const wms_request_url = `${gt_constant.GEOSERVER_WMS_URL}${gt_constant.GEOSERVER_CAPABILITY_URL_PARAMETER}`;
      SessionService.set_session(gt_constant.MAP_WMS_REQUEST_SESSION_KEY,wms_request_url);
      const options = {
        headers: {
          Authorization: id_token
        }
      };

      service.get_WMS_layer_with_header(wms_request_url, options).then((wmscapability => {
        const json = new WMSCapabilities(wmscapability).toJSON();
        SessionService.set_session(gt_constant.GT_GRO_SERVER_EXIST_SESSION_KEY,true);
        set_wms_layer(json);

      }), (fail) => {
        //console.log(fail);
        SessionService.set_session(gt_constant.GT_GRO_SERVER_EXIST_SESSION_KEY,false);
        set_wms_layer([]);
      }
      ).catch(ex => {
        SessionService.set_session(gt_constant.GT_GRO_SERVER_EXIST_SESSION_KEY,false);
        set_wms_layer([]);
      });
    });
  }
   /**
   * @method get_company_info
   * @description This method load current user associated customer information
   */
  const get_company_info = () => {
    service.get(gt_constant.GT_API_URL, gt_constant.GT_API_CUSTOMER_GET).then((res) => {
      set_customer(res);
    }).catch(ex => {
      if (ex.code == "ERR_NETWORK") {
        return set_data_service_available(false);
      } else if (ex.response && ex.response.status == 500) {
        return set_data_service_available(false);
      }
    });

  }
  /**
   * @method get_company_products
   * @description This method load current user associated customer products
   */
  const get_company_products = () => {

    service.get(gt_constant.GT_API_URL, gt_constant.PRODUCT_API_GET).then((res) => {
      let contents = res.content;
      let uniqueGroups = [
        ...new Map(contents.map((item) => [item["product_group_name"], item])).values(),
      ];
      set_site_list(contents);
      set_product_list(uniqueGroups);
      
      
      
    }, (fail) => {
      if(fail.response?.status==404){
        const path='/noproduct';
        history.push(path);
        return;
      }
      return set_data_service_available(false);
    }
    ).catch(ex => {      
      if (ex.code == "ERR_NETWORK") {
        return set_data_service_available(false);
      }
      else if (ex.response && ex.response.status == 500) {
        return set_data_service_available(false);
      }
    });

  }
  /**
   * @method get_products_images
   * @description This method load current user associated customer products images.
   */
  const get_products_images = () => {
    const payload = {
      "aoi_id": null,
      "product_group_id": null
    };
    service.post(gt_constant.GT_API_URL, gt_constant.GT_API_PRODUCT_IMAGES, payload).then((res) => {
      let contents = res.content;
      set_product_images(contents);
    }).catch(ex => {
      if (ex.code == "ERR_NETWORK") {
        return set_data_service_available(false);
      } else if (ex.response && ex.response.status == 500) {
        return set_data_service_available(false);
      }
    });

  }
  /*const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }*/

  /**
   * @method change_aoi
   * @description This method invoked when aoi changed
   */
  const change_aoi = (new_aoi) => {
    set_aoi_id(new_aoi);
  }
  if (sites_for_selected_product && sites_for_selected_product.length == 1 && sites_for_selected_product[0].aoi_id != aoi_id) {
    set_aoi_id(sites_for_selected_product[0].aoi_id);
  }
  const should_render_dashboard = sites_for_selected_product.filter(s => s.aoi_id == aoi_id)?.length > 0 ? true : false;
  const company_logo = customer?.logo_url;
  const companyurl = 'https://www.hoganas.com';
  //console.log('sites_for_selected_product=',sites_for_selected_product)


  return (
    <Route {...rest} render={props => (
      <div className='dashboardbody'>

        <Preloader show={loaded || !should_render_dashboard ? false : true} />
        {data_service_available && company_id != -1 && product_list && product_list.length > 0 ? <Sidebar products={product_list} /> : null}
        {data_service_available && company_id != -1 ? <TopBanner logourl={company_logo} source='dashboard' companyurl={companyurl} ></TopBanner> : null}
        {company_id != -1 && sites_for_selected_product && sites_for_selected_product.length > 1 ? <SiteMenu sites={sites_for_selected_product} change_aoi={change_aoi}></SiteMenu> : null}

        <main className="content globalgurdbody">
          {/* <Navbar /> */}
          {data_service_available == false && !is_overview() ? <GTDataServiceError /> : null}
          {company_id == -1 ? <GTCompanyAssociationError /> : null}
          {data_service_available && should_render_dashboard && company_id != -1 ? <Component dashboard={rest.dashboard} aoi_id={aoi_id} sites={sites_for_selected_product} productimages={selected_product_images} wmscapability={wms_layer} wmsworkspace={`cus_${get_company_id()}_site_${aoi_id}`} /> : loaded ? <Component {...props} /> : null}
          {/* <Footer toggleSettings={toggleSettings} showSettings={showSettings} /> */}
        </main>
      </div>
    )}
    />
  );
};

/**
 * @module HomePage
 * @description This serves as a foundational wrapper component. Depending on the routing configuration, it renders the appropriate component.
 *
 * @example
 *
 * <HomePage  />
 *
 * @author Samaresh
 */
export default () => (
  <Switch>
    <RouteWithLoader exact path={Routes.GTIndex.path} component={GTLandingPage} />

    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
    <RouteWithLoader exact path={Routes.NoProductFound.path} component={NotFoundProduct} />

    {/* pages */}

    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={GlobalGuardOverview} />
    <RouteWithSidebar exact path={Routes.Vegetation.path} component={GlobalGurdDashboard} dashboard="Vegetation" />
    <RouteWithSidebar exact path={Routes.LandCover.path} component={GlobalGurdDashboard} dashboard="LULC" />
    <RouteWithSidebar exact path={Routes.Water.path} component={GlobalGurdDashboard} dashboard="Water" />
    <RouteWithSidebar exact path={Routes.Atmospheric.path} component={GlobalGurdDashboard} dashboard="Atmospherics" />
    <RouteWithSidebar exact path={Routes.Social.path} component={GlobalGurdDashboard} dashboard="Social" />
    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
